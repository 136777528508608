import {
  Button,
  Checkbox,
  Input,
  Menu,
  MenuProps,
  Spin,
  Switch,
  Table,
  notification,
} from "antd";
import axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import TextArea from "antd/es/input/TextArea";
import { CustomInput } from "../../utils/CustomeInput";
import { baseUrl } from "../../database/NewConnection";
import { IConnectionDbColumnConfig } from "../../database/DatabaseDetail/DatabaseDetail";
import { SendOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export type NotificationType = "success" | "info" | "warning" | "error";

interface IDBConfigProps {
  modalInput: string;
  setModalInput: (modalInput: string) => void;
  getData: any;
  setIsModalOpen: (isModalOpen: boolean) => void;
  showCheckBox: boolean;
}

const DBShortView = (props: IDBConfigProps) => {
  const { modalInput, setModalInput, getData, setIsModalOpen, showCheckBox } =
    props;
  const { state } = useLocation();
  const [connectionDbConfig, setConnectionDbConfig] = useState<{
    [key: string]: Array<IConnectionDbColumnConfig>;
  }>(state?.db_config ? state.db_config : {});
  const { connectionConfigId } = useParams();
  const [isConnectionConfigLoading, setIsConnectionConfigLoading] =
    useState(false);
  const [disabledTables, setDisabledTables] = useState<Array<string>>([]);
  const [dbSummaryJson, setDbSummaryJson] = useState<{
    [key: string]: { description: string };
  }>({});
  const onChangeChecked = (e: CheckboxChangeEvent) => {
    const localStorageData = JSON.parse(
      localStorage.getItem("OpenModalOnStart") || "{}"
    );
    const key = connectionConfigId || "s";
    const isChecked = !e.target.checked;

    localStorageData[key] = isChecked; // Update the value for the specific key

    localStorage.setItem("OpenModalOnStart", JSON.stringify(localStorageData));
  };

  useEffect(() => {
    setIsConnectionConfigLoading(true);
    axios
      .get(`${baseUrl}/connection/${connectionConfigId}/`)
      .then(function (response) {
        // setConnectionName(response.data.name);
        setConnectionDbConfig(response.data.db_config);
        setDisabledTables(response.data.disabled_tables);
        setDbSummaryJson(response.data.db_summary_json);
        setIsConnectionConfigLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionConfigLoading(false);
      });
  }, [connectionConfigId]);

  const [isConnectionDbConfigUpdating, setIsConnectionDbConfigUpdating] =
    useState<boolean>(false);

  const [selectedTableName, setSelectedTableName] = useState<string>(
    connectionDbConfig ? Object.keys(connectionDbConfig).sort()[0] : ""
  );
  const [
    isConnectionConfigRefershLoading,
    setIsConnectionConfigRefershLoading,
  ] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  const refreshDBConfig = () => {
    setIsConnectionConfigRefershLoading(true);
    axios
      .put(`${baseUrl}/connection/${connectionConfigId}/`, {})
      .then(function (response) {
        setConnectionDbConfig(response.data.db_config);
        setIsConnectionConfigRefershLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionConfigRefershLoading(false);
      });
  };

  useEffect(() => {
    // if (!selectedTableName) {
    setSelectedTableName(
      connectionDbConfig ? Object.keys(connectionDbConfig).sort()[0] : ""
    );
    // }
  }, [connectionDbConfig]);

  const onClick: MenuProps["onClick"] = (e: any) => {
    setSelectedTableName(e.key);
  };

  const updateConnectionDbConfigValue = (
    column: string,
    key: string,
    value: any
  ) => {
    const tmpConnectionDbConfig = { ...connectionDbConfig };
    var columnDetails: any = [...tmpConnectionDbConfig[selectedTableName]].find(
      (row) => row.name === column
    );
    columnDetails[key] = value;
    console.log(columnDetails, tmpConnectionDbConfig);
    setConnectionDbConfig(tmpConnectionDbConfig);
  };

  const updateConnectionDbConfig = () => {
    setIsConnectionDbConfigUpdating(true);
    axios
      .put(`${baseUrl}/connection/${connectionConfigId}/update-db-config/`, {
        db_config: connectionDbConfig,
        disabled_tables: disabledTables,
        db_summary_json: dbSummaryJson,
      })
      .then(function (response) {
        setIsConnectionDbConfigUpdating(false);
        openNotificationWithIcon("success", response.data.message);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionDbConfigUpdating(false);
        openNotificationWithIcon("error", error.response.data.error);
      });
  };

  const columns: ColumnsType<IConnectionDbColumnConfig> = [
    {
      title: "Column Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ellipsis: true,
    },
    // {
    //   title: "Updated Name",
    //   dataIndex: "name",
    //   key: "name",
    //   ellipsis: true,
    //   render: (text) => (
    //     <>
    //       <Input disabled value={text} />
    //     </>
    //   ),
    // },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      width: 150,
      render: (enabled, { name }) => (
        // <Switch
        //   key={`${selectedTableName}-${name}`}
        //   defaultChecked={enabled !== undefined ? enabled : true}
        //   onChange={(value) => {
        //     updateConnectionDbConfigValue(name, "enabled", value);
        //   }}
        //   disabled
        // />
        <div key={`${selectedTableName}-${name}`}>
          {enabled !== undefined ? enabled ? "Yes" : "No" : "Yes"}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: "Foreign Key",
    //   dataIndex: "foreign_key",
    //   key: "foreign_key",
    //   width: 120,
    // },
    // {
    //   title: "Primary Key",
    //   dataIndex: "is_primary_key",
    //   key: "is_primary_key",
    //   width: 120,
    //   render: (is_primary_key) => <>{is_primary_key ? "Yes" : "No"}</>,
    // },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 250,
    //   render: (text, { name }) => (
    //     <>
    //       <TextArea
    //         value={text}
    //         onChange={(e) => {
    //           updateConnectionDbConfigValue(
    //             name,
    //             "description",
    //             e.target.value
    //           );
    //         }}
    //       />
    //     </>
    //   ),
    // },
  ];
  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node!.getBoundingClientRect();
    setTableHeight(window.innerHeight - top - 120);
  }, [ref]);

  return (
    <>
      {contextHolder}
      {connectionDbConfig && (
        <>
          {isConnectionConfigLoading ? (
            <div
              style={{
                display: "flex",
                height: "-webkit-fill-available",
                justifyContent: "center",
                padding: "40px",
              }}
            >
              <Spin style={{ alignSelf: "center" }} />
              &nbsp;&nbsp; Loading Database Config
            </div>
          ) : (
            <>
              <div
                style={{
                  // height: "600px",
                  // padding: "20px",
                  // paddingBottom: "55px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <div
                  style={{ display: "flex", gap: 10, justifyContent: "right" }}
                >
                  <Button disabled>Generate Description</Button>
                  <Button
                    type="primary"
                    loading={isConnectionConfigRefershLoading}
                    onClick={refreshDBConfig}
                  >
                    Refresh DB
                  </Button>
                  <Button
                    type="primary"
                    loading={isConnectionDbConfigUpdating}
                    onClick={updateConnectionDbConfig}
                  >
                    Update
                  </Button>
                </div> */}
                <br />
                <div style={{ display: "flex", gap: 10, flex: 1 }}>
                  <div style={{ height: 480, overflow: "scroll" }}>
                    <Menu
                      onClick={onClick}
                      style={{ minWidth: 156 }}
                      selectedKeys={[selectedTableName]}
                      mode="inline"
                      items={Object.keys(connectionDbConfig)
                        .sort()
                        ?.map((tableName) => ({
                          key: tableName,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 10,
                              }}
                            >
                              {tableName}

                              {/* <div onClick={(e) => e.stopPropagation()}>
                                <Switch
                                  defaultChecked={
                                    disabledTables &&
                                    !disabledTables.includes(tableName)
                                  }
                                  disabled
                                  onChange={(value) => {
                                    if (value) {
                                      var tmp = [...disabledTables];
                                      tmp = tmp.filter(
                                        (item) => item !== tableName
                                      );
                                      setDisabledTables(tmp);
                                    } else {
                                      var tmp = [...disabledTables];
                                      tmp.push(tableName);
                                      setDisabledTables(tmp);
                                    }
                                  }}
                                />
                              </div> */}
                            </div>
                          ),
                        }))}
                    />
                  </div>
                  <div style={{ flex: 1, width: "100px" }}>
                    {/* <TextArea
                      key={selectedTableName}
                      placeholder={`${selectedTableName} table description`}
                      value={
                        dbSummaryJson
                          ? dbSummaryJson[selectedTableName]?.description
                          : ""
                      }
                      onChange={(e) => {
                        var tmpDbSummaryJson = dbSummaryJson
                          ? { ...dbSummaryJson }
                          : { [selectedTableName]: { description: "" } };
                        tmpDbSummaryJson[selectedTableName].description =
                          e.target.value;
                        setDbSummaryJson(tmpDbSummaryJson);
                      }}
                    /> */}
                    {/* <br />
                    <br /> */}
                    <Table
                      // style={{ height: "85vh" }}
                      columns={columns}
                      dataSource={
                        selectedTableName
                          ? connectionDbConfig[selectedTableName]
                          : []
                      }
                      loading={isConnectionConfigLoading}
                      ref={ref}
                      scroll={{ x: "max-content", y: 350 }}
                    // scroll={{ x: "max-content", y: "70vh" }}
                    />
                  </div>
                </div>
                <Input
                  placeholder="Send a message..."
                  value={modalInput}
                  onChange={(e) => setModalInput(e.target.value)}
                  onPressEnter={() => {
                    if (modalInput !== "") {
                      setIsModalOpen(false);
                      setModalInput("");
                      getData(modalInput);
                    }
                  }}
                  suffix={
                    <SendOutlined
                      disabled={modalInput === ""}
                      style={{
                        cursor: modalInput === "" ? "not-allowed" : "pointer",
                        backgroundColor:
                          modalInput === "" ? "white" : "#1677ff",
                        padding: "6px",
                        borderRadius: "3px",
                        color: modalInput === "" ? "grey" : "white",
                      }}
                      onClick={() => {
                        if (modalInput !== "") {
                          setIsModalOpen(false);
                          setModalInput("");
                          getData(modalInput);
                        }
                      }}
                    />
                  }
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DBShortView;
