import { ArrowLeftOutlined } from "@ant-design/icons";
import { Popover, Checkbox, Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import axios from "axios";
import { configToUse } from "../../../Keycloak";

const FEEDBACK_OPTIONS = {
  Yes: [
    { label: "Actionable insight", value: "actionable" },
    { label: "Looks interesting", value: "interesting" },
    { label: "Very surprising", value: "surprising" },
    { label: "Scope for improvement exists", value: "improvement" },
  ],
  No: [
    { label: "The insight isn't very useful", value: "disqualified" },
    { label: "The insight is wrong", value: "bad" },
  ],
};
const baseUrl = configToUse.REACT_APP_API_BASE_URL;

export interface BasicFeedback {
  liked?: boolean;
  selections?: [];
  custom?: string;
}
interface FeedbackProps extends BasicFeedback {
  id: string;
  para?: string | undefined;
  in_detail?: boolean;
  onSubmit: Function;
}

function parseLikedValue(val: boolean | undefined) {
  return val === null || val === undefined ? "" : val ? "Yes" : "No";
}

export default function Feedback(props: FeedbackProps) {
  const { id, liked, selections, custom, in_detail, para, onSubmit } = props;
  const [insightLiked, setInsightLiked] = useState<CheckboxValueType[]>([
    parseLikedValue(liked),
  ]);
  const [optionSelections, setOptionSelections] = useState<CheckboxValueType[]>(
    selections ?? []
  );
  const [customText, setCustomText] = useState(custom ?? "");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const outsideRef = useRef<HTMLSpanElement | null>(null);
  const [isCustom, setIsCustom] = useState(false);
  const radioOptions = [
    {
      label: (
        <Popover
          key="Yes"
          autoAdjustOverflow={false}
          open={insightLiked[0] === "Yes" && popoverOpen}
          placement="bottom"
          arrow={false}
          content={getPopoverContent()}
          {...(isCustom && getCustomPopverContents())}
        >
          <span>Yes</span>
        </Popover>
      ),
      value: "Yes",
    },
    {
      label: (
        <Popover
          key="No"
          autoAdjustOverflow={false}
          open={insightLiked[0] === "No" && popoverOpen}
          arrow={false}
          placement="bottom"
          content={getPopoverContent()}
          {...(isCustom && getCustomPopverContents())}
        >
          <span>No</span>
        </Popover>
      ),
      value: "No",
    },
  ];

  function getCustomPopverContents() {
    const content = (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <ArrowLeftOutlined
            onClick={(e) => {
              e.stopPropagation();
              setIsCustom(false);
            }}
          />
          <span style={{ textAlign: "center", width: "100%" }}>
            Custom Feedback
          </span>
        </div>
        <div>
          <Input.TextArea
            rows={4}
            value={customText}
            onChange={(e) => setCustomText(e.target.value)}
          />
          <Button
            block
            style={{
              backgroundColor: "#5E5ADB",
              color: "white",
              marginTop: "8px",
            }}
            disabled={!optionSelections.length && !(customText ?? "").length}
            onClick={() => {
              setPopoverOpen(false);
              setIsCustom(false);
              submitFeedback();
            }}
          >
            Submit
          </Button>
        </div>
      </>
    );
    return content;
  }

  function getPopoverContent() {
    return (
      <div
        ref={popupRef}
        style={{ height: "fit-content", width: "fit-content" }}
      >
        {isCustom ? (
          getCustomPopverContents()
        ) : (
          <>
            <Checkbox.Group
              options={
                insightLiked.length
                  ? FEEDBACK_OPTIONS[insightLiked[0] as "Yes" | "No"]
                  : []
              }
              value={optionSelections}
              onChange={(values: CheckboxValueType[]) =>
                setOptionSelections(values)
              }
              style={{ display: "flex", flexDirection: "column" }}
            />
            <div
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#030712",
                marginTop: "20px",
              }}
            >
              Custom Feedback
            </div>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setIsCustom(true);
              }}
              block
            >
              Write to us
            </Button>
            <Button
              block
              style={{
                backgroundColor: "#5E5ADB",
                color: "white",
                marginTop: "8px",
              }}
              disabled={!optionSelections.length && !customText.length}
              onClick={() => {
                setPopoverOpen(false);
                setIsCustom(false);
                submitFeedback();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    );
  }

  const onInsightLikeChange = (value: CheckboxValueType[]) => {
    let newarr = [...value];
    let addValue = newarr[0];
    for (const ele of newarr) {
      if (!insightLiked.includes(ele)) {
        addValue = ele;
        break;
      }
    }

    if (insightLiked.length && value.length) {
      setInsightLiked([addValue]);
      setOptionSelections([]);
      setCustomText("");
      setPopoverOpen(true);
    }

    if (insightLiked.length && !value.length) {
      if (optionSelections.length || (customText ?? "").length) {
        setPopoverOpen(true);
      } else {
        setInsightLiked([]);
        setOptionSelections([]);
        setCustomText("");
        setPopoverOpen(false);
      }
    }

    if (!insightLiked.length && value.length) {
      setInsightLiked([addValue]);
      setPopoverOpen(true);
    }
  };
  function submitFeedback() {
    if (in_detail) {
      onSubmit(para ?? "", id, {
        liked: insightLiked[0] === "Yes",
        selections: optionSelections,
        custom: customText,
      });

      return;
    }

    const data = {
      insight_id: id,
      feedback: {
        liked: insightLiked[0] === "Yes",
        selections: optionSelections,
        custom: customText,
      },
    };
    axios
      .post(`${baseUrl}/insight/${id}/feedback`, { ...data })
      .then((response) => onSubmit("success", response.data.message))
      .catch((error) => onSubmit("error", error.response.data.message));
  }

  const resetOptions = () => {
    setInsightLiked([parseLikedValue(liked)]);
    setOptionSelections(selections ?? []);
    setCustomText(custom ?? "");
    setIsCustom(false);
  };

  const handleOutsideClick = (e: any) => {
    console.log("called", popupRef?.current, outsideRef?.current);
    if (popupRef.current && outsideRef.current) {
      console.log("inside refs");
      if (
        !popupRef.current.contains(e.target) &&
        !outsideRef.current.contains(e.target)
      ) {
        console.log("inside contains");
        resetOptions();
        setPopoverOpen(false);
      }
    }
  };

  const handleOutOfView = (entries: any[]) => {
    entries.forEach((entry) => {
      const itRatio = entry.intersectionRatio;
      if (itRatio < 0.5) {
        resetOptions();
        setPopoverOpen(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleOutOfView, {
      threshold: 0,
    });
    let closureRef: Element;
    if (outsideRef.current) {
      closureRef = outsideRef.current;
      observer.observe(closureRef);
    }
    return () => {
      if (closureRef) {
        observer.unobserve(closureRef);
      }
    };
  }, []);
  useEffect(() => {
    if (popoverOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [popoverOpen]);

  return (
    <span ref={outsideRef}>
      <Checkbox.Group
        options={radioOptions}
        value={insightLiked}
        onChange={onInsightLikeChange}
      />
    </span>
  );
}
