import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Divider, ConfigProvider, Collapse, CollapseProps } from "antd";
import { IQuestionAnswerPropsV2 } from "../Interact";
import { Slider } from "./Slider";
import { Chart, formatString } from "./Chart";
import { BulbOutlined, DownOutlined } from "@ant-design/icons";
import { ExportWidget } from "./ExportWidget";
import { Feedback } from "./Feedback";
import CustomLoader from "../../utils/CustomLoader";
import crux_small_icon from "../../../assets/crux_small.svg"
import { PromptInput } from "./PromptInput";
import style from '../Interact.module.css'
import CodeBlock from "../../utils/CodeBlock";
import chatIcon from '../../../assets/icons/chat.svg'
import brainStromIcon from '../../../assets/icons/brainstrom.svg'
import analyseIcon from '../../../assets/icons/analyse.svg'

import { formatTimestamp } from "../../utils/Helpers";
import { SpaceCompactItemContext } from "antd/es/space/Compact";
import { Radio } from "antd";

const query_variables = { "query_variables_except_date": [{ "variable_name": "string", "variable_value": "string" }], "time_period": "string" }

const QueryResult = (props: { row: IQuestionAnswerPropsV2, updateQuestionGraph: any }) => {
  const { row, updateQuestionGraph } = props;
  const [seletedTab, setSelectedTab] = useState<string>("data")
  const [slideButtons, setSlideButtons] = useState<Array<any>>([])
  useEffect(() => {
    let temp = []
    console.log(row)
    // if data is there there will be no data tab
    if (row.data.length > 0) temp.push({ name: "Data", value: "data" })
    temp.push({ name: "Summary", value: "summary" })
    // if data not,  then graph will not or If data is there graph is not then Show No graph available
    if (row.data.length > 0)
      temp.push({ name: "Graph", value: "graph" })
    // if query is present 
    if (row.query)
      temp.push({ name: "Query", value: "query" })

    // if data is not there selected tab will be summary summary will always be there
    if (row.data.length <= 0)
      setSelectedTab("summary")
    setSlideButtons(temp)
  }, [row])


  const [openVariables, setOpenVariables] = useState<boolean>(true);
  // initailse with empty string or values from row
  const queryVariablesExceptDate = row?.query_variables?.query_variables_except_date || [];
  const timePeriodVariable = row?.query_variables?.time_period ? { variable_name: "time_period", variable_value: row?.query_variables?.time_period } : null;
  console.log(queryVariablesExceptDate, timePeriodVariable)
  const [variables, SetVariables] = useState([...queryVariablesExceptDate, ...(timePeriodVariable?.variable_value ? [timePeriodVariable] : [])]);
  console.log(variables)
  useEffect(() => {
    console.log(variables)
  }, [variables])
  return (
    <div className={style.queryResult}>
      {/* feedback */}
      <div className={style.resultFeedback} style={{}} >
        {variables.toString() &&
          <Button style={
            {
              borderRadius: "8px",
              border: "1px",
              boxShadow: "0px 0px 0px 2px rgba(16, 24, 40, 0.08)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }
          }
            onClick={() => {
              setOpenVariables((pre) => !pre)

            }}>
            <span style={{
              // fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(52, 64, 84, 1)"

            }} >Variables used to fetch data</span>
            <DownOutlined style={{ fontWeight: "600", color: "rgba(52, 64, 84, 1)", transform: openVariables ? "rotate(180deg)" : "", transition: "transform 0.3s ease" }} />
          </Button>}
        <div style={{ display: "flex", gap: "12px", marginLeft: "auto" }}>
          <Feedback
            feedback={row.feedback ? row.feedback : {}}
            questionId={row.questionId ? row.questionId : ""}
          />
          <ExportWidget
            data={{
              question: row.question ? row.question : "",
              questionId: row.questionId ? row.questionId : "",
              data: row.data,
              graphs: row.graphs,
              summary: row.answer,
            }}
          />
        </div>
      </div>

      {variables.toString() && openVariables && <div style={{ width: "100%", display: "flex", gap: "12px", overflowX: "auto", overflowY: "hidden" }}>
        {
          variables.map(({ variable_name, variable_value }) => <div style={{
            display: "flex",
            padding: "10px 12px 10px 12px",
            borderRadius: "6px",
            border: "1px solid rgba(229, 231, 235, 1)",
            fontWeight: "600",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            marginBottom: "6px"

          }}>
            <span style={{ whiteSpace: "nowrap", color: "rgba(52, 64, 84, 1)" }} >{variable_name}: &nbsp;</span>
            <span style={{ color: "rgba(94, 90, 219, 1)", whiteSpace: "nowrap" }}>{variable_value}</span>
          </div>)
        }
      </div>
      }
      <Slider
        buttons={
          slideButtons
        }
        defaultValue={seletedTab}
        onValueChange={(v: string) => {
          setSelectedTab(v)
        }}
      />
      {
        seletedTab === "data" && row.data && row.data.length > 0 && (

          <Table
            className={style.tableStyle}
            style={{ flex: 1 }} // Set the table's flex to distribute remaining space
            columns={Object.keys(row.data[0]).map((v) => ({
              title: formatString(v),
              dataIndex: v,
              ellipsis: true,
              width: formatString(v).length * 12
            }))}
            dataSource={row.data}
            scroll={{ x: "max-content", y: `${20 * 50}px` }}

          />
        )
      }


      {
        seletedTab === "query" &&
        <div style={{ width: "100%" }}>
          <CodeBlock language="sql" code={row?.query || ""} />

          <div>
            <div className={style.textheading}>Explanation</div>
            <div className={style.normaltext}>
              {row.sqlQueryExplanation}
            </div>
          </div>

        </div>
      }
      {
        seletedTab === "graph" && <div style={{ width: "100%", }}>
          {row.graphFetching ? (
            <CustomLoader
              text="Looking for relevant graph"
            />
          ) : (
            <Chart
              questionId={row.questionId}
              graphs={row.graphs}
              data={row.data}
              updateQuestionGraph={updateQuestionGraph}
            />
          )}
        </div>
      }
      {
        seletedTab === "summary" && <div>
          {row.summaryFetching ? (
            <>
              <CustomLoader
                text="Generating the summary"
              />
            </>
          ) : (
            typeof row.answer === "string" && (
              <div
                className={style.summary}
                dangerouslySetInnerHTML={{
                  __html:
                    row.answer &&
                    row.answer
                      .replace(/\n/g, "<br />")
                      .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                }}
              ></div>
            )
          )}
        </div>
      }
    </div >
  )

}


const ChatV2 = (props: {
  questionAnswerListV2: IQuestionAnswerPropsV2[];
  setQuestionAnswerListV2: React.Dispatch<
    React.SetStateAction<IQuestionAnswerPropsV2[]>
  >;
  getData: any;
  callExampleQuestion: any;
  sampleQuestionList: any;
  isLoading: any,
  FollowUpQuestions: any
}) => {
  const {
    questionAnswerListV2,
    setQuestionAnswerListV2,
    getData,
    callExampleQuestion,
    sampleQuestionList,
    isLoading,
    FollowUpQuestions
  } = props;

  console.log(questionAnswerListV2);


  const isChartExist = (row: IQuestionAnswerPropsV2) => {
    console.log("is chart exist", row)
    return (
      row.chartSelection === "BarChart" ||
      row.chartSelection === "LineChart" ||
      row.chartSelection === "PieChart" ||
      row.chartSelection === "MultiLineChart"
    );
  };
  const updateQuestionGraph = (questionId: string, graphList: any) => {
    const updatedList = [...questionAnswerListV2];
    updatedList.filter(
      (questionAnswer) => questionAnswer.questionId === questionId
    )[0].graphs = graphList;
    setQuestionAnswerListV2(updatedList);
  };

  // Scrolling chat container to bottom
  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    console.log("qqqq called");
    if (chatContainerRef.current) {
      console.log("qqqq inside if called");
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [isLoading])


  const [exampleButtonState, setExampleButtonState] = useState("brainstorm")
  useEffect(() => {
    console.log(exampleButtonState)
  }, [exampleButtonState])


  return (
    <div style={{ height: "100%", padding: "14px 16px", display: "flex", flexDirection: "column", justifyContent: "space-between", }}>
      {/* Examples Questions - Start*/}
      {questionAnswerListV2.length === 0 && (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "100%", marginTop: "4rem", }}>
          {sampleQuestionList && (
            <>
              <Radio.Group className={style.exampleRadioButton} defaultValue="brainstorm" value={exampleButtonState} onChange={(e) => setExampleButtonState(e.target.value)} size="large" style={{
                display: "flex",
                // boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                border: "1px solid rgba(237, 237, 237, 1)",
                borderRadius: "8px",
                padding: "2px",
                background: "rgba(244, 244, 244, 1)",

              }}>
                {!Array.isArray(sampleQuestionList) ?
                  (<>
                    <Radio.Button value="brainstorm"

                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#1c1c1c",
                        borderRadius: "6px",
                        borderColor: exampleButtonState === "analyse" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        padding: "9px, 20px, 9px, 20px",
                        borderWidth: "1px",
                        background: exampleButtonState === "analyse" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        boxShadow: exampleButtonState === "analyse" ? "" : "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                      }}>
                      <img src={brainStromIcon} alt="" style={{ marginRight: "12px", position: "relative", top: "3px", height: "20px", width: "20px" }} />
                      <span style={{ fontWeight: "500", fontSize: "16px", color: "rgba(31, 31, 31, 1)" }}>
                        Brainstorm
                      </span></Radio.Button>
                    <Radio.Button value="analyse"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#1c1c1c",
                        padding: "9px, 20px, 9px, 20px",
                        borderWidth: "1px",
                        background: exampleButtonState === "brainstorm" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        borderColor: exampleButtonState === "brainstorm" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        borderRadius: "6px",
                        boxShadow: exampleButtonState === "brainstorm" ? "" : "0px 0px 2px 0px rgba(0, 0, 0, 0.25)"

                      }}
                    >
                      <img src={analyseIcon} alt="" style={{ marginRight: "12px", position: "relative", top: "3px", height: "20px", width: "20px" }} />
                      <span>
                        Analyse </span></Radio.Button>
                  </>
                  ) : (
                    <Radio.Button value="brainstorm"

                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#1c1c1c",
                        borderRadius: "6px",
                        borderColor: exampleButtonState === "analyse" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        padding: "9px, 20px, 9px, 20px",
                        borderWidth: "1px",
                        background: exampleButtonState === "analyse" ? "rgba(244, 244, 244, 1)" : "rgba(255, 255, 255, 1)",
                        boxShadow: exampleButtonState === "analyse" ? "" : "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                      }}>
                      <img src={brainStromIcon} alt="" style={{ marginRight: "12px", position: "relative", top: "3px", height: "20px", width: "20px" }} />
                      <span style={{ fontWeight: "500", fontSize: "16px", color: "rgba(31, 31, 31, 1)" }}>
                        Brainstorm
                      </span></Radio.Button>
                  )}

              </Radio.Group>

              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "0.8rem" }}>



                {Array.isArray(sampleQuestionList) ?
                  <>
                    {(sampleQuestionList.map((row: any) => (
                      <div
                        onClick={() => {
                          callExampleQuestion(row);
                        }}
                        className={style.sampleQuestion}
                        style={{
                          width: "60%",
                          height: "auto",
                          padding: "12px",
                          fontSize: "15px",
                          borderRadius: "10px",
                          whiteSpace: "normal",
                          marginTop: "1rem",

                        }}
                      >
                        {`"${row}" `}
                      </div>)))}
                  </> :
                  <>
                    {exampleButtonState === "brainstorm" &&
                      sampleQuestionList["brainstorm"].map((row: any) => (
                        <div
                          onClick={() => {
                            callExampleQuestion(row);
                          }}
                          className={style.sampleQuestion}
                          style={{
                            width: "60%",
                            height: "auto",
                            padding: "12px",
                            fontSize: "15px",
                            borderRadius: "10px",
                            whiteSpace: "normal",
                            marginTop: "1rem"

                          }}
                        >
                          {`"${row}" `}
                        </div>
                      ))
                    }
                    {exampleButtonState === "analyse" &&
                      sampleQuestionList["analyse"].map((row: any) => (
                        <div
                          onClick={() => {
                            callExampleQuestion(row);
                          }}
                          className={style.sampleQuestion}
                          style={{
                            width: "60%",
                            height: "auto",
                            padding: "12px",
                            fontSize: "15px",
                            borderRadius: "10px",
                            whiteSpace: "normal",
                            marginTop: "1rem",
                          }}
                        >
                          {`"${row}" `}
                        </div>
                      ))}


                  </>

                }
              </div>
            </>
          )}
        </div>
      )
      }
      {/* Examples Questions - End*/}

      {/* Chats - Start */}
      <div className="hidden-scrollbar" ref={chatContainerRef} style={{ overflowY: "auto", padding: "10px" }}>
        {/* Question-Answer Mapping */}
        {questionAnswerListV2.map((row, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "column", }}>
            {/* Chat You Sent */}
            <div style={{ display: "flex", alignSelf: "end", maxWidth: "40%", flexDirection: "column", gap: "5px", fontSize: "14px", padding: "20px" }}>
              <div>
                You
              </div>
              <div style={{ padding: "10px 14px 10px 14px", color: "white", background: "#5E5ADB", borderEndEndRadius: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>
                {row.question}
              </div>
            </div >
            {/* Resopnse You Got */}
            <div
              style={{
                display: "flex",
                gap: 10,
                padding: "20px 20px 20px 10px",
                borderRadius: "16px",
                backgroundColor: "#fff",
                maxWidth: "55%"
              }}
            >
              {/* avatar */}
              <div style={{}}>
                <img
                  src={crux_small_icon}
                  alt="avatar"
                  style={{ height: "40px", }}
                />
              </div>
              {row.isLoading ? (
                <CustomLoader
                  // isLoading={row.isLoading}
                  text={
                    questionAnswerListV2[index - 1]?.type === "completion" || index === 0 || questionAnswerListV2[index - 1].type === "query"
                      ? "Understanding your question"
                      : questionAnswerListV2[index - 1].type ===
                        "final_confirmation"
                        ? "Fetching the data"
                        : "Analysing the information you provided"
                  }
                />
              ) : (
                <div >
                  <span style={{
                    color: "var(--colors-text-text-tertiary-600, #475467)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "18px"
                  }}>{formatTimestamp(row?.timestamp)}</span>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      marginTop: "5px"
                    }}
                  >
                    {/* if type completion */}
                    {row.type === "completion" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.answer +
                              ""
                                .replace(/\n/g, "<br />")
                                .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                          }}
                          style={{
                            padding: "12px 14px 12px 14px",
                            border: "1px solid #EAECF0",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            background: "#F9FAFB"
                          }}
                        ></div>
                      </>
                    )}
                    {/* if type clarification */}
                    {row.clarificationData && row.type === "clarification" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.clarificationData?.question +
                              ""
                                .replace(/\n/g, "<br />")
                                .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                          }}
                          style={{
                            padding: "12px 14px 12px 14px",
                            border: "1px solid #EAECF0",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            background: "#F9FAFB"
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            gap: 10,
                          }}
                        >
                          {row.clarificationData.options.map(
                            (option: string, i: number) => (
                              <button
                                key={i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const updatedList = [...questionAnswerListV2];
                                  updatedList[index].clarificationData.options = [];
                                  setQuestionAnswerListV2(updatedList);
                                  getData(option);
                                }}
                                className={style.optionBtn}
                                style={{

                                }}
                              >
                                {option}
                              </button> // Wrapped each option in a <span> and added a key
                            )
                          )}
                        </div>
                      </>
                    )}
                    {/* if type final confirmation */}
                    {row.finalConfirmationData &&
                      row.type === "final_confirmation" && (
                        <>
                          <div style={{

                            padding: "12px 14px 16px 14px",
                            border: "1px solid #EAECF0",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            background: "#F9FAFB"
                          }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  "Just to confirm, here is a summary of our conversation:"
                                    .replace(/\n/g, "<br />")
                                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                              style={{ marginBottom: "10px" }}

                            ></div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  "<b>" +
                                  row.finalConfirmationData?.redefined_input +
                                  "</b>"
                                    .replace(/\n/g, "<br />")
                                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                              style={{ marginBottom: "10px" }}
                            ></div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: "Does this looks good?"
                                  .replace(/\n/g, "<br />")
                                  .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                            ></div>
                          </div>

                          {!row.finalConfirmationData?.confirmed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  justifyContent: "flex-end",
                                  // justifyContent: "space-around",
                                  gap: 10,
                                }}
                              >
                                <button
                                  className={style.optionBtn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const updatedList = [...questionAnswerListV2];
                                    updatedList[
                                      index
                                    ].finalConfirmationData.confirmed = true;
                                    setQuestionAnswerListV2(updatedList);
                                    getData("Yes");
                                  }}
                                >
                                  {"Yes, show me the answer"}
                                </button>
                                <button
                                  className={style.optionBtn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const updatedList = [...questionAnswerListV2];
                                    updatedList[
                                      index
                                    ].finalConfirmationData.confirmed = true;
                                    setQuestionAnswerListV2(updatedList);
                                    getData("No, this isn't quite right");
                                  }}
                                >
                                  {"No, this isn't quite right"}
                                </button>
                              </div>

                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    {row.type === "query" && (
                      <QueryResult row={row} updateQuestionGraph={updateQuestionGraph} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div >
        ))}
      </div>
      <div style={{}}>
        {/* folowup questions */}
        {FollowUpQuestions && FollowUpQuestions?.length !== 0 && (
          <div
            className="hidden-scrollbar"
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: 10,
              // justifyContent: "space-between",
              scrollbarWidth: "none",
              flexWrap: "nowrap",
              cursor: "default",
              alignItems: "center",


              marginBottom: "10px"
            }}
          >
            <span style={{ color: "#6C727580", fontSize: "14px", fontWeight: "600" }}>Explore</span>
            {FollowUpQuestions.map((question: string, i: any) => {
              return (
                <div
                  key={i}
                  style={{
                    flex: "0 0 auto",
                    padding: "10px",
                    border: "1px solid rgba(94, 90, 219, 0.15)",
                    borderRadius: "10px",
                    color: "#5E5ADB",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    // setFollowUpQuestions([]);
                    getData(question);
                  }}
                >
                  ✨ {question}
                </div>
              );
            })}
          </div>
        )}

        <PromptInput getData={getData} isLoading={isLoading} />
      </div>
    </div >
  );
};

export default ChatV2;
