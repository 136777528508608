import { Button, notification } from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { NotificationType } from "../database/DatabaseDetail/DBConfig";
import { format } from 'sql-formatter';

const copySVG = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_3157_25551)">
    <path d="M3.78442 10.2057C3.16317 10.2057 2.85254 10.2057 2.60751 10.1042C2.28081 9.96891 2.02124 9.70935 1.88592 9.38264C1.78442 9.13761 1.78442 8.82699 1.78442 8.20573V3.6724C1.78442 2.92566 1.78442 2.55229 1.92975 2.26708C2.05758 2.01619 2.26155 1.81222 2.51244 1.68439C2.79765 1.53906 3.17102 1.53906 3.91776 1.53906H8.45109C9.07235 1.53906 9.38297 1.53906 9.628 1.64056C9.95471 1.77588 10.2143 2.03545 10.3496 2.36215C10.4511 2.60718 10.4511 2.91781 10.4511 3.53906M8.58442 14.8724H12.9844C13.7312 14.8724 14.1045 14.8724 14.3897 14.7271C14.6406 14.5992 14.8446 14.3953 14.9724 14.1444C15.1178 13.8592 15.1178 13.4858 15.1178 12.7391V8.33906C15.1178 7.59233 15.1178 7.21896 14.9724 6.93374C14.8446 6.68286 14.6406 6.47889 14.3897 6.35105C14.1045 6.20573 13.7312 6.20573 12.9844 6.20573H8.58442C7.83769 6.20573 7.46432 6.20573 7.1791 6.35105C6.92822 6.47889 6.72425 6.68286 6.59642 6.93374C6.45109 7.21896 6.45109 7.59233 6.45109 8.33906V12.7391C6.45109 13.4858 6.45109 13.8592 6.59642 14.1444C6.72425 14.3953 6.92822 14.5992 7.1791 14.7271C7.46432 14.8724 7.83769 14.8724 8.58442 14.8724Z" stroke="#344054" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0_3157_25551">
      <rect width="16" height="16" fill="white" transform="translate(0.450928 0.203125)" />
    </clipPath>
  </defs>
</svg>

const CodeBlock = (props: { language: string, code: string }) => {
  const { language, code } = props;


  const [api, contextHolder] = notification.useNotification()

  const showToastMessage = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "topRight",
    });
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    showToastMessage('info', "SQL query copied to clipboard")
  };


  return (
    <div>
      {contextHolder}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
        <span style={{
          color: "var(--colors-text-text-primary-900, #101828)",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "28px" /* 200% */
        }}>SQL</span>
        <button
          className="copy-btn"
          style={{
            display: "flex", alignItems: "center", gap: "5px", background: "white", borderRadius: "var(--radius-md, 8px)",
            padding: "6px 10px",
            border: "1px solid #D0D5DD",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          }}
          onClick={() => copyToClipboard(code)}
        >
          {copySVG}
          < span style={{ fontWeight: "600", color: "#344054" }} > Copy</span>
        </button>
      </div >
      <SyntaxHighlighter
        className="syntax-highlighter syntax-scrollbar"
        language={language}
        style={vs2015}
        showLineNumbers={true}
        wrapLines={true}
        lineNumberStyle={{ minWidth: '2em', marginRight: '1em', textAlign: 'right', }}
      >
        {format(code, { language: "postgresql" })}
      </SyntaxHighlighter>
    </div >
  );
};

export default CodeBlock