import { ExportOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, ConfigProvider, Divider, Modal, Select, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { WidgetPreview } from "./WidgetPreview";
import axios from "axios";
import { baseUrl } from "../../database/NewConnection";
import style from "../Interact.module.css"
export interface IWidgetConfigProps {
  [key: string]: {
    enabled?: boolean;
    position?: number;
    chart?: Array<any>;
  };
}

export interface IExportWidgetProps {
  question: string;
  questionId: string;
  data: any;
  graphs: any;
  summary: string;
}

type NotificationType = "success" | "info" | "warning" | "error";
export const ExportWidget = (props: { data: IExportWidgetProps }) => {
  const { data } = props;
  const [widgetConfig, setWidgetConfig] = useState<IWidgetConfigProps>({});
  const [position, setPosition] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewWidgetCreating, setIsNewWidgetCreating] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [api, contextHolder] = notification.useNotification();
  const [tags, setTags] = useState<Array<string>>([]);
  const openNotificationWithIcon = (
    type: NotificationType,
    description: string
  ) => {
    api[type]({
      message: "Success",
      description: description,
      placement: "bottomRight",
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateWidgetConfig = (e: any, name: string) => {
    var tmpWidgetConfig = { ...widgetConfig };
    tmpWidgetConfig[name] = {};
    tmpWidgetConfig[name].enabled = e.target.checked;
    tmpWidgetConfig[name].position = position;
    setPosition(position + 1);
    setWidgetConfig(tmpWidgetConfig);
  };

  const updateWidgetChartConfig = (e: any) => {
    console.log(e);
    var tmpWidgetConfig = { ...widgetConfig };
    if (
      widgetConfig["graph"] &&
      widgetConfig["graph"].chart &&
      widgetConfig["graph"].chart?.length > 0
    ) {
      tmpWidgetConfig["graph"] = {
        position: tmpWidgetConfig["graph"].position,
        enabled: true,
        chart: e.map((index: number) => data.graphs[index]),
      };
    } else {
      tmpWidgetConfig["graph"] = {
        position: position,
        enabled: true,
        chart: data.graphs.filter((graph: any, i: number) => e.includes(i)),
      };
    }
    setPosition(position + 1);
    setWidgetConfig(tmpWidgetConfig);
  };

  const addNewWidget = () => {
    // setIsNewWidgetCreating(true);
    // axios
    //   .post(`${baseUrl}/chat/v3/question/new-widget/`, {
    //     title: title,
    //     widgetConfig: widgetConfig,
    //     questionId: data.questionId,
    //     tags: tags,
    //   })
    //   .then(function (response) {
    //     setIsNewWidgetCreating(false);
    //     handleCancel();
    //     openNotificationWithIcon(
    //       "success",
    //       `Widget created with id ${response.data.widgetId}`
    //     );
    //     //   updateGraph(graphList[selectedChart], "is_editing", false)
    //   })
    //   .catch(function (e) {
    //     setIsNewWidgetCreating(false);
    //   });

    console.log("Code for saving widget")
  };

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#5E5ADB"
      }
    }}>
      {contextHolder}
      <Button onClick={showModal}
        icon={<SaveOutlined />}
        className={style.feedbackBtn}
      >

      </Button>
      <Modal
        open={isModalOpen}
        footer={null}
        style={{
          top: 20,
          bottom: 20,
          maxHeight: "95vh",
          overflowY: "scroll",
        }}
        width={"70vw"}
        onOk={handleOk}
        onCancel={handleCancel}

      >
        {/* <h2>{data.question}</h2> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            width: "30vw",
            margin: "10px",
          }}
        >
          <div>
            <span>
              <Checkbox
                style={{ margin: "0 5px 5px 0" }}
                onChange={(e) => updateWidgetConfig(e, "title")}
                checked
              ></Checkbox>
              Widget Title
            </span>
            <TextArea
              rows={2}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              style={{ margin: "0 5px 0 0" }}
              onChange={(e) => updateWidgetConfig(e, "summary")}
            ></Checkbox>
            <span>Summary</span>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              style={{ margin: "0 5px 0 0" }}
              onChange={(e) => updateWidgetConfig(e, "data")}
            ></Checkbox>
            <span>Data</span>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span>Graph</span>
            <Select
              mode="multiple"
              // size={size}
              placeholder="Please select"
              onChange={updateWidgetChartConfig}
              style={{ width: "100%" }}
              options={data.graphs?.map((row: any, index: number) => ({
                label: `Graph-${index + 1}(${row.graph_name})`,
                value: index,
              }))}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <span>Tags(For reference only):</span>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Tags"
              value={tags}
              onChange={(v) => setTags(v)}
            // options={options}
            />
          </div>
          <br />
          <span>
            Click on{" "}
            <Checkbox style={{ margin: "0 5px" }} checked={true}></Checkbox> to
            get that field on the widget
          </span>
        </div>
        <Divider />
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2 style={{ flex: 1 }}>Preview</h2>
            <Button
              disabled={
                Object.keys(widgetConfig).filter((row: any) => row.enabled)
                  .length > 0
              }
              type="primary"
              onClick={addNewWidget}
              loading={isNewWidgetCreating}
            >
              Save Widget
            </Button>
          </div>
          <div
            style={{
              border: "1px solid #000",
              minHeight: "100px",
              padding: "10px",
            }}
          >
            <WidgetPreview
              data={data}
              widgetConfig={widgetConfig}
              title={title}
            />
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
