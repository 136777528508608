import { Badge, Divider } from "antd";
import style from '../Interact.module.css'

export const Slider = (props) => {
    const { buttons, defaultValue, onValueChange } = props;
    return (
        <div style={{ width: "100%", }}>
            <div className={style.slider}>
                {
                    buttons.map(row => (
                        <div className={row.value !== defaultValue ? style.sliderOption : style.sliderOption + " " + style.sliderSelectedOption}
                            onClick={() => { onValueChange(row.value) }}
                        >
                            {row.icon}<span>{" "}{row.name}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}