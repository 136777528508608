import "./App.css";
import PrivateRoute from "./helpers/PrivateRoute";
import { useEffect, useState } from "react";
import {
  MoreOutlined,
  CodeSandboxOutlined,
  UsergroupAddOutlined,
  SolutionOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Avatar, Dropdown, Layout, Menu, Modal, theme, ConfigProvider, Divider } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Database } from "./screens/database/Database";
import { Widget } from "./screens/Widget";
import { Admin } from "./screens/Admin";
import { NewConnection } from "./screens/database/NewConnection";
import { DatabaseDetail } from "./screens/database/DatabaseDetail/DatabaseDetail";
import Interact from "./screens/interact/Interact";
import AdvanceInteract from "./screens/advanceInteract/AdvanceInteract";
import { configToUse } from "./Keycloak";
import CrossIcon from "./assets/cross.svg";
import { BrowserView, MobileView } from "react-device-detect";
import Insight from "./screens/insight/Insight";
import InsightDetailPage from "./screens/insight/InsightDetailPage.tsx/InsightDetailPage";
import InsightSvg from './assets/navLogo/insight.svg'
import ChatSvg from './assets/navLogo/chat.svg'
import DatabseSvg from './assets/navLogo/databse.svg'
import WidgetSvg from './assets/navLogo/widget.svg'
import AdvanceqnaSVG from './assets/navLogo/advanceqna.svg'
import Ferrous from "./screens/ferrous/Ferrous";
import {ProductFruits} from 'react-product-fruits'
import axios from "axios";



const { Header, Content, Footer, Sider } = Layout;
interface IMenuItemsProps {
  key: string;
  icon: any;
  label: string;
  realm: string
}

function App() {
  const [mobileModal, setMobileModal] = useState(true);
  const openModal = () => {
    setMobileModal(true);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuItems: Array<IMenuItemsProps> = [
    {
      key: "insight",
      icon: <img src={InsightSvg} />,
      label: "Insights",
      realm: "Insight"
    },
    {
      key: "interact",
      icon: <img src={ChatSvg} />,
      label: "Interact",
      realm: "Interact",

    }, {
      key: "ferrous",
      icon: <CodeSandboxOutlined style={{ fontSize: '24px', color: "#667085" }} />,
      label: "Ferrous",
      realm: 'ferrous'
    },
    {
      key: "",
      icon: <img src={DatabseSvg} />,
      label: "Database",
      realm: "Database",

    },
    {
      key: "widget",
      icon: <img src={WidgetSvg} />,
      label: "Widget",
      realm: "Widget",

    },
    {
      key: "Admin",
      icon: <UsergroupAddOutlined />,
      label: "Admin",
      realm: "Admin",

    },
    {
      key: "advance_qna",
      icon: <img src={AdvanceqnaSVG}/>,
      label: "Advance QnA",
      realm: "advance_qna",

    }
  ];
  const { keycloak } = useKeycloak();
  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.split("/")[1]
  );
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrentTab(e.key);
    navigate("/" + e.key);
  };

  function parseJwt (token:string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
  // on every rendering
  useEffect(() => {
    setCurrentTab(window.location.pathname.split("/")[1])
  })


  return (
    <PrivateRoute
    >
      <ConfigProvider
        theme={{
          components: {
            Layout: {

            }
          }
        }}>
        <Layout style={{ minHeight: "100vh", }} hasSider >
          <MobileView>
            <Modal
              open={mobileModal}
              closeIcon={null}
              centered
              onCancel={(e) => setMobileModal(false)}
              maskClosable={true}
              width={"90%"}
              style={{ padding: "24px" }}
              zIndex={4}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignSelf: "flex-end",
                    }}
                  >
                    <img
                      src={CrossIcon}
                      style={{ cursor: "pointer", width: "60px" }}
                      onClick={(e) => setMobileModal(false)}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "50px",
                      fontWeight: 600,
                      textAlign: "center",
                      marginBottom: "60px",
                      width: "80%",
                    }}
                  >
                    Crux platform works best on larger screens.
                  </div>
                  <div
                    style={{
                      fontSize: "40px",
                      fontWeight: 400,
                      textAlign: "center",
                      marginBottom: "60px",
                      width: "80%",
                    }}
                  >
                    We recommend trying out the platform on desktop or laptop
                    computer for better experience.
                  </div>
                  <div
                    style={{
                      width: "56%",
                      cursor: "pointer",
                      borderRadius: "8px",
                      padding: "20px 0",
                      border: "none",
                      background: "#5E5ADB",
                      textAlign: "center",
                      alignSelf: "center",
                      color: "white",
                      fontWeight: 400,
                      fontSize: "40px",
                    }}
                    onClick={(e) => setMobileModal(false)}
                  >
                    Continue Anyway
                  </div>
                </div>
              }
              footer={null}
            >
              <></>
            </Modal>
          </MobileView>
          <ConfigProvider theme={{
            token: {

            },
            components: {
              Layout: {


              }

            }
          }}>
            <ProductFruits workspaceCode={configToUse.REACT_APP_PRODUCT_FRUITS_KEY??""} language="en" lifeCycle="unmount" user={{username:axios.defaults.headers.common.Authorization?.toString() ? parseJwt(axios.defaults.headers.common.Authorization?.toString())["email"] : "test"}}/>
            <Sider
              trigger={null}

              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              style={{
                overflow: "auto",
                height: "100vh",
                background: colorBgContainer,
                zIndex: "5",

              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <div
                  style={{ margin: "30px 35px 20px 25px", cursor: "pointer" }}
                // onClick={() => { window.open('https://getcrux.ai/', '_blank') }}
                >
                  <img
                    src={configToUse.REACT_APP_SMALL_LOGO}
                    style={{ width: "35px", display: collapsed ? "block" : "none" }}
                  />
                  <img
                    src={configToUse.REACT_APP_BIG_LOGO}
                    style={{
                      width: "105px",
                      display: collapsed ? "none" : "block",
                    }}
                  />
                </div>
                <ConfigProvider
                  theme={{
                    components: {
                      Menu: {
                        itemActiveBg: "#E9EDF5",
                        itemSelectedBg: "#E9EDF5"
                      },
                    },
                  }}>
                  <Menu
                    theme="light"
                    mode="inline"
                    selectedKeys={[currentTab]}
                    onClick={onClick}
                    style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', border: "none" }}
                  >
                    {menuItems.map((row) =>
                      keycloak.hasRealmRole(row.realm) ? (
                        <Menu.Item className={collapsed ? 'collasped' : ''} key={row.key} icon={row.icon} style={{ border: "none" }}  >
                          {row.label}
                        </Menu.Item>
                      ) : (
                        <></>
                      )
                    )}
                  </Menu>
                </ConfigProvider>
                <div
                  style={{
                    margin: collapsed
                      ? "0 0 10px 0"
                      : "15px 10px 60px 10px",
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                    // border: "2px solid red",
                    height: "64px",
                    justifyContent: "center"

                  }}
                >
                  {collapsed ? (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="logout">
                            <a
                              onClick={() => {
                                keycloak.logout();
                              }}
                            >
                              Logout
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["hover"]}
                    >
                      <Avatar
                        style={{
                          backgroundColor: "#ff6000",
                          verticalAlign: "middle",
                        }}
                        size="large"
                      >
                        {keycloak.idTokenParsed?.preferred_username
                          .charAt(0)
                          .toUpperCase()}
                      </Avatar>
                    </Dropdown>
                  ) : (
                    <>
                      <Avatar
                        style={{
                          backgroundColor: "#ff6000",
                          verticalAlign: "middle",
                        }}
                        size="default"
                      >
                        {keycloak.idTokenParsed?.preferred_username
                          .charAt(0)
                          .toUpperCase()}
                      </Avatar>
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                          flex: 1,
                        }}
                      >
                        {keycloak.idTokenParsed?.preferred_username}
                      </div>

                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="logout">
                              <a
                                onClick={() => {
                                  keycloak.logout();
                                }}
                              >
                                Logout
                              </a>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["hover"]}
                      >
                        <MoreOutlined style={{ cursor: "pointer" }} />
                      </Dropdown>
                    </>
                  )}
                </div>
              </div>
            </Sider>
          </ConfigProvider>

          <Divider
            type="vertical"
            style={{
              height: "100%",
              margin: "0px",
              borderColor: "#EAECF0",
              borderWidth: "1px"
            }}
          />
          <Layout  >
            <Content
              style={{
                overflow: "initial",
                display: "flex",
              }}
            >
              <div
                style={{
                  height: "100vh",
                  background: colorBgContainer,
                  fontFamily: "Optimo Plain",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <Routes>
                  <Route path="" element={<Database />} />
                  <Route path="/new-connection" element={<NewConnection />} />
                  <Route
                    path="/connection/:connectionId"
                    element={<DatabaseDetail />}
                  />
                  <Route
                    path="/interact/:connectionConfigId?/:chatId?"
                    element={<Interact />}
                  />
                  <Route
                    path="/advance_qna/:connectionConfigId?/:chatId?"
                    element={<AdvanceInteract />}
                  />
                  <Route path="/insight/*" element={<Insight />} />
                  <Route path="/insight/:id" element={<InsightDetailPage />} />
                  <Route path="/widget" element={<Widget />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/ferrous" element={<Ferrous />} />
                  <Route path='/advance_qna' element={<AdvanceInteract />} />
                </Routes>
              </div>
            </Content>
            {/* <Footer style={{ textAlign: "center", padding: "20px" }}>
            Crux ©2023
          </Footer> */}
          </Layout>
        </Layout>
      </ConfigProvider>
    </PrivateRoute >
  );
}

export default App;