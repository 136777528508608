import { Spin } from "antd";
import Lottie from "lottie-react";
import loaderAnimation from '../../assets/Loader.json'

const CustomLoader = (props: { text: string }) => {
  const { text } = props;
  return (
    <div
      style={{
        background: "rgb(243, 246, 252)",
        padding: "10px 20px",
        display: "flex",
        gap: 10,
        width: "fit-content",
        justifyContent: "space-around",
        borderRadius: "10px",
        borderTopLeftRadius: "0",
        alignItems: "center",
      }}
    >
      <div className="spinner"></div>
      <div style={{ display: "block" }}>{text}</div>
      {/* 
      <Lottie

        animationData={loaderAnimation}
        rendererSettings={{ preserveAspectRatio: "none" }}
        style={{ margin: "-42px -72px -50px -72px", width: "200px", }}

      /> */}
    </div>
  );
};

export default CustomLoader;
