import {
  Button,
  Divider,
  Select,
  TreeSelect,
  Skeleton,
  notification,
  List,
} from "antd";
import styles from "./Insight.module.css";
import { useEffect, useRef, useState } from "react";
import { DownOutlined, FilterOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
// filter icons
import metricSvg from "../../assets/metric.svg";
import segmentSvg from "../../assets/segment.svg";
import frequencySvg from "../../assets/frequency.svg";
import categorySvg from "../../assets/category.svg";
import emptySvg from "../../assets/emptyBox.svg";

import { getFormattedDate } from "../utils/Helpers";
import axios from "axios";
import { configToUse } from "../../Keycloak";
import Feedback, { BasicFeedback } from "./components/Feedback";
import { useNavigate, useSearchParams } from "react-router-dom";
import InsightTags from "./components/InsightTags";
import Graph from "./components/Graph";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotificationType } from "../database/DatabaseDetail/DBConfig";
import lineBG from "../../assets/LineBackground.png";
import { IConnectionProps } from "../database/Database";

enum FilterType {
  Select = "select",
  Tree = "tree",
}

export enum CategoryType {
  Threat = "Threat",
  Opportunity = "Opportunity",
}

export interface InsightFeedProps {
  id: string;
  metric: { metric_label: string };
  title: string;
  summary: string;
  frequency: { frequency_label: string };
  segment_value_list: { [key: string]: string }[];
  type: CategoryType;
  data_for_graph: { [key: string]: [] };
  created_at: string;
  feedback: {
    feedback: BasicFeedback;
    summary: { [key: number]: { para: string; feedback: BasicFeedback } };
  };
}
interface IInsightConfig {
  id: string;
  name: string;
  connection: string;
  filters: { type: string; key: string }[];
  [key: string]: any;
}
interface IConnectionConfigs extends IConnectionProps {
  configs: IInsightConfig[];
}

const filterIconMap = (name: string) => {
  switch (name) {
    case "metric":
      return <img src={metricSvg} alt="" />;
    case "segment":
      return <img src={segmentSvg} alt="" />;
    case "frequency":
      return <img src={frequencySvg} alt="" />;
    case "category":
      return <img src={categorySvg} alt="" />;
    default:
      return <FilterOutlined />;
  }
};
const baseUrl = configToUse.REACT_APP_API_BASE_URL;

export function BoldText(text: string): string {
  if (!text) return "";

  const parts = text.split(/\*\*(.*?)\*\*/);
  return parts.map((part, index) => {
    const regex = /\*(.*?)\*/;

    if (regex.test(part)) {
      return part.split(regex).map((subpart, idx) => {
        if (idx % 2 === 1) {
          // Apply bold styling
          return `<span style="font-weight:bold; color: rgb(16, 24, 40);">${subpart}</span>`;
        } else {
          // Regular text
          return subpart;
        }
      }).join('');
    }

    if (index % 2 === 1) {
      // Apply section title styling
      return `<span style="font-size: 17px; font-weight: 700; color: rgb(16, 24, 40);">${part}</span>`;
    } else {
      return part;
    }
  }).join('');
}

export default function Insight() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { connectionId, configId } = Object.fromEntries(searchParams);

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [next, setNext] = useState(null);
  const [insightList, setInsightList] = useState<InsightFeedProps[]>([]);
  const [connectionList, setConnectionList] = useState<IConnectionConfigs[]>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(true);
  const location = useLocation();
  const [dataForDisplay, setDataForDisplay] = useState<InsightFeedProps[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const initialRender = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${baseUrl}/insight/configs`)
      .then((response) => {
        setConnectionList(response.data);
        setDefaultConfigs(response.data);
        setDefaultFilters(response.data);
        initialRender.current = false;
      })
      .catch((_) =>
        showToastMessage("error", "Unable to fetch configurations")
      );
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const filterParams = getParamsForFilters();
    let url = `${baseUrl}/insight/all?${filterParams.length
      ? filterParams.join("&") + "&items_per_page=10"
      : "items_per_page=10"
      }`;
    setLoading(true);
    if (configId && !initialRender.current) {
      url = `${url}&config_id=${configId}`;
      axios
        .get(url, {
          cancelToken: source.token,
        })
        .then((response) => {
          processInsightGetRespone(response);
          setLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            showToastMessage("error", err.message);
          }
        });

      const currentParams = new URLSearchParams(searchParams.toString());
      Object.entries(filters).forEach((ele) =>
        currentParams.set(ele[0], ele[1])
      );
      setSearchParams(currentParams);
    }

    return () => source.cancel();
  }, [filters]);

  function processInsightGetRespone(response: any) {
    setInsightList(response.data.results);
    setNext(response.data.next);
    setTotalCount(response.data.count);
    setDataForDisplay(response.data.results.slice(0, 10));
  }

  function getParamsForFilters() {
    const selectedConfig = connectionList
      .find((ele: any) => ele.id === connectionId)
      ?.configs.find((ele: any) => ele.id === configId);

    const paramsv2 = [];

    for (const key of Object.keys(filters)) {
      if (typeof filters[key] === "string") {
        if (filters[key] !== "all") {
          paramsv2.push(`${key}=${filters[key]}`);
        }
      } else {
        let selectedFilter = null;
        if (selectedConfig) {
          selectedFilter = selectedConfig[key];
        }

        if (Array.isArray(filters[key]) && !filters[key].includes("overall")) {
          for (const each of filters[key]) {
            if (selectedFilter) {
              const findParent = selectedFilter.find(
                (ele: { name: any }) => ele.name === each
              );
              if (findParent) {
                for (const option of findParent.child) {
                  paramsv2.push(`${key}=${each}:${option.name}`);
                }
              } else {
                paramsv2.push(`${key}=${each}`);
              }
            }
          }
        }
      }
    }
    return paramsv2;
  }

  function setDefaultFilters(data: any) {
    const selectedConfig = data
      .find((ele: any) => ele.id === connectionId)
      ?.configs.find((ele: any) => ele.id === configId);
    let tempFilters = {};
    if (selectedConfig) {
      const qparams = new URLSearchParams(location.search);
      for (const { key, type } of selectedConfig.filters) {
        if (type === FilterType.Select) {
          let val = qparams.get(key);
          if (!val) {
            val = "all";
          }
          tempFilters = { ...tempFilters, [key]: val };
        } else {
        }
        if (type === FilterType.Tree) {
          let val = qparams.getAll(key);
          if (!val.length) {
            val = ["overall"];
          }
          tempFilters = { ...tempFilters, [key]: val };
        }
      }
    }
    setFilters(tempFilters);
  }

  function setDefaultConfigs(data: IConnectionConfigs[]) {
    if (!configId) handleConfigChange(null);

    if (configId || connectionId) return;
    const dataToUse = data.find(
      (item) => item.configs && item.configs.length > 0
    );
    if (dataToUse) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set("connectionId", dataToUse?.configs[0].connection);
      updatedSearchParams.set("configId", dataToUse?.configs[0].id);
      setSearchParams(updatedSearchParams);
    }
  }

  function showToastMessage(type: NotificationType, message: string) {
    api[type]({
      message: message,
      placement: "topRight",
    });
  }

  const fetchMoreData = () => {
    axios
      .get(`${next}`)
      .then((response) => {
        const newData = response.data.results;
        setTotalCount(response.data.count);
        setNext(response.data.next);
        if (newData.length > 0) {
          setDataForDisplay([...dataForDisplay, ...newData]);
        }
      })
      .catch((err) => showToastMessage("error", err.message));
  };

  const getInsightConfigsList = (id: string = connectionId) => {
    const selectedConnection = connectionList.find((ele) => ele.id === id);
    const val = selectedConnection
      ? selectedConnection.configs.map((config) => ({
        value: config.id,
        label: <span style={{ fontWeight: "600" }}>{config.name}</span>,
      }))
      : [];

    return val;
  };

  const handleConfigChange = (
    value: string | null,
    id: string = connectionId
  ) => {
    setLoading(true);
    if (value) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      const filterParams = getParamsForFilters();
      updatedSearchParams.set("configId", value);
      setSearchParams(updatedSearchParams);

      let getUrl = `${baseUrl}/insight/all?config_id=${value}&items_per_page=10&${filterParams.join(
        "&"
      )}`;
      axios
        .get(getUrl)
        .then((response) => {
          processInsightGetRespone(response);
          setLoading(false);
        })
        .catch((err) => {
          showToastMessage("error", err.message);
          setLoading(false);
        });
    } else {
      if (getInsightConfigsList(id).length === 0) {
        setLoading(false);
      }
      processInsightGetRespone({ data: { results: [], next: null, count: 0 } });
    }
  };

  const handleConnectionChange = (value: any) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("connectionId", value);
    updatedSearchParams.delete("configId");
    handleConfigChange(null, value);
    setSearchParams(updatedSearchParams);
  };

  const handleFilter = (group: string, value: any, type: string) => {
    if (type === FilterType.Tree) {
      if (value.length === 0) setFilters({ ...filters, [group]: ["overall"] });
      else if (value.at(-1) === "overall")
        setFilters({ ...filters, [group]: ["overall"] });
      else if (value.length !== 0)
        setFilters({
          ...filters,
          [group]: value.filter((item: string) => item !== "overall"),
        });
    } else {
      setFilters({ ...filters, [group]: value });
    }
  };

  const getFilters = () => {
    if (connectionId && configId) {
      const selectedConfig = connectionList
        .find((ele) => ele.id === connectionId)
        ?.configs.find((ele) => ele.id === configId);

      const options = selectedConfig?.filters?.map((ele, idx) => {
        const selectOptions = [{ label: "All", value: "all" }];
        selectedConfig[ele.key].map((ele: { name: string }) => {
          selectOptions.push({ value: ele.name, label: ele.name });
        });
        if (ele.type === FilterType.Select) {
          return (
            <div className={styles.filterItem} key={`${ele.key}-${idx}`}>
              {filterIconMap(ele.key)}
              <span className={styles.title}>{ele.key + " :"}</span>
              <Select
                defaultValue={selectOptions[0]["value"]}
                options={selectOptions}
                value={filters[ele.key]}
                onChange={(value) => handleFilter(ele.key, value, ele.type)}
                style={{ width: "100%" }}
                dropdownStyle={{ minWidth: "200px" }}
                bordered={false}
              />
            </div>
          );
        }
        if (ele.type === FilterType.Tree) {
          const treeOptions = [
            { label: "Overall", value: "overall", children: [] },
          ] as { label: string; value: string; children: any }[];
          selectedConfig[ele.key].map(
            (ele: { name: string; child: { name: string }[] }) =>
              treeOptions.push({
                value: ele.name,
                label: ele.name,
                children: ele.child
                  ? ele.child.map((cele) => {
                    return {
                      label: cele.name,
                      value: `${ele.name}:${cele.name}`,
                    };
                  })
                  : [],
              })
          );
          return (
            <div className={styles.filterItem}>
              {filterIconMap(ele.key)}
              <span className={styles.title}>{ele.key + " :"}</span>
              <TreeSelect
                defaultValue={treeOptions[0]["value"]}
                treeData={treeOptions}
                value={filters[ele.key]}
                onChange={(value) => handleFilter(ele.key, value, ele.type)}
                treeCheckable
                showCheckedStrategy={"SHOW_PARENT"}
                bordered={false}
                showSearch={false}
                maxTagCount={3}
                treeDefaultExpandAll
                dropdownStyle={{ minWidth: "250px" }}
              />
            </div>
          );
        }
      });
      options?.push(
        <div className={styles.filterItem}>
          {filterIconMap("category")}
          <span className={styles.title}>{"category" + " :"}</span>
          <Select
            defaultValue={["all"]}
            options={[
              { value: "all", label: "All" },
              { value: CategoryType.Threat, label: "Threat" },
              { value: CategoryType.Opportunity, label: "Opportunity" },
            ]}
            value={filters["category"]}
            onChange={(value) =>
              handleFilter("category", value, FilterType.Select)
            }
            style={{ width: "100%" }}
            dropdownStyle={{ minWidth: "200px" }}
            bordered={false}
          />
        </div>
      );
      return options;
    } else return [];
  };

  const getFeeds = (data: InsightFeedProps[]) => {
    if (loading) {
      return Array.from({ length: 3 }).map((_) => {
        return (
          <div className={styles.feedCard}>
            <Skeleton paragraph={{ rows: 4 }} />
          </div>
        );
      });
    }
    if (!data.length || !configId)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "480px",
              height: "320px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${lineBG})`,
            }}
          >
            <img src={emptySvg} alt="no data" />
          </div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "30px",
              margin: "-70px 0 7px 0",
            }}
          >
            No Data found
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#475467",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              marginBottom: "28px",
            }}
          >
            Your selected filters do not have any data. <br /> Please try again.
          </div>
        </div>
      );
    return data.map((ele) => {
      const graphData =
        ele.data_for_graph[ele.metric.metric_label]?.map(
          (points: { [key: string]: string }) => {
            return {
              [ele.frequency.frequency_label]: getFormattedDate(
                points[ele.frequency.frequency_label]
              ),
              [ele.metric.metric_label]: parseFloat(
                points[ele.metric.metric_label] as string
              ),
            };
          }
        ) ?? [];

      return (
        <div className={styles.feedCard}>
          <div className={styles.content}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#101828",
              }}
            >{ele.title}</div>
            <div>
              <InsightTags {...ele} />
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#475467",
                  marginBottom: "4px",
                }}
              >
                {getFormattedDate(ele.created_at)}
              </span>
              <div className={styles.summary} dangerouslySetInnerHTML={{ __html: BoldText(ele.summary) }}></div>
            </div>
            <div style={{ marginTop: "16px" }}>
              <span>Is this insight helpful? &nbsp;</span>
              <Feedback
                id={ele.id}
                key={ele.id}
                {...(ele.feedback ? ele.feedback.feedback : {})}
                onSubmit={showToastMessage}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              maxWidth: "50%",
              height: "400px",
              gap: "12px",
              marginTop: "75px",
            }}
          >
            <Graph
              data={graphData as []}
              xField={ele.frequency.frequency_label}
              yField={ele.metric.metric_label}
            />
            <div style={{ alignSelf: "flex-end" }}>
              <Button
                style={{ backgroundColor: "#5E5ADB", color: "white" }}
                onClick={() => navigate(ele.id, { relative: "path" })}
              >
                See More
              </Button>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px" }}>
      {contextHolder}
      <div
        style={{
          position: "sticky",
          top: "0px",
          backgroundColor: "white",
          zIndex: "2",
        }}
      >
        <h1 style={{ margin: "12px 0px" }}>Insights</h1>
        <div className={styles.filters} style={{ marginBottom: "16px" }}>
          <div className={styles.filterItem}>
            <span className={styles.title} style={{ color: "#030712" }}>
              {"Connection Name :"}
            </span>
            <Select
              {...(connectionList.length > 0
                ? {
                  suffixIcon: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        fontSize: "8px",
                      }}
                    >
                      <UpOutlined />
                      <DownOutlined />
                    </div>
                  ),
                }
                : {})}
              options={connectionList.map(
                (item: { id: string; name: string }) => {
                  return {
                    value: item.id,
                    label: (
                      <span style={{ fontWeight: "600" }}>{item.name}</span>
                    ),
                  };
                }
              )}
              value={connectionId}
              loading={!connectionList.length}
              style={{ minWidth: "100px" }}
              dropdownStyle={{ minWidth: "200px" }}
              placeholder="Select a connection to proceed"
              onChange={(value) => handleConnectionChange(value)}
              bordered={false}
            />
          </div>
          <div className={styles.filterItem}>
            <span className={styles.title} style={{ color: "#030712" }}>
              {"Insight Config :"}
            </span>
            <Select
              {...(connectionList.length > 0
                ? {
                  suffixIcon: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        fontSize: "8px",
                      }}
                    >
                      <UpOutlined />
                      <DownOutlined />
                    </div>
                  ),
                }
                : {})}
              options={getInsightConfigsList()}
              value={configId}
              loading={!connectionList.length}
              style={{ minWidth: "100px" }}
              dropdownStyle={{ minWidth: "200px" }}
              placeholder="Select a insight config proceed"
              onChange={(value) => handleConfigChange(value)}
              bordered={false}
            />
          </div>
        </div>
        <div className={styles.filters}>{getFilters()}</div>
        <Divider />
      </div>
      <div className={styles.feedList} id="scrollableFeedList">
        <InfiniteScroll
          dataLength={dataForDisplay.length}
          hasMore={dataForDisplay.length < totalCount}
          scrollableTarget="scrollableFeedList"
          next={fetchMoreData}
          loader={
            <List>
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  borderRadius: "10px",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                <Skeleton paragraph={{ rows: 4 }} active />
              </div>
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  borderRadius: "10px",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                <Skeleton paragraph={{ rows: 4 }} active />
              </div>
            </List>
          }
          endMessage={
            insightList.length ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: "#eaecf0",
                    height: "1px",
                    flexGrow: "1",
                  }}
                ></span>
                <span style={{ margin: "0px 12px", fontWeight: "600" }}>
                  <i>"Enough insights for the day"</i>
                </span>
                <span
                  style={{
                    backgroundColor: "#eaecf0",
                    height: "1px",
                    flexGrow: "1",
                  }}
                ></span>
              </div>
            ) : null
          }
        >
          {getFeeds(dataForDisplay)}
        </InfiniteScroll>
      </div>
    </div>
  );
}
