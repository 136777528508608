import {
  Button,
  Input,
  Menu,
  MenuProps,
  Spin,
  Switch,
  Table,
  notification,
} from "antd";
import axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { baseUrl } from "../NewConnection";
import type { ColumnsType } from "antd/es/table";
import TextArea from "antd/es/input/TextArea";
import { CustomInput } from "../../utils/CustomeInput";
import { IConnectionDbColumnConfig } from "./DatabaseDetail";

export type NotificationType = "success" | "info" | "warning" | "error";

interface IDBConfigProps {
  connectionName: string;
  connectionId: string;
  isConnectionConfigLoading: boolean;
  connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  };
  setConnectionDbConfig: (connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  }) => void;
  disabledTables: Array<string>;
  setDisabledTables: (disabledTables: Array<string>) => void;
  dbSummaryJson: { [key: string]: { description: string } };
  setDbSummaryJson: (key: { [key: string]: { description: string } }) => void;
}

export const DBConfig = (props: IDBConfigProps) => {
  const {
    connectionId,
    isConnectionConfigLoading,
    connectionDbConfig,
    setConnectionDbConfig,
    disabledTables,
    setDisabledTables,
    dbSummaryJson,
    setDbSummaryJson,
  } = props;
  const [isConnectionDbConfigUpdating, setIsConnectionDbConfigUpdating] =
    useState<boolean>(false);

  const [selectedTableName, setSelectedTableName] = useState<string>(
    connectionDbConfig ? Object.keys(connectionDbConfig).sort()[0] : ""
  );
  const [
    isConnectionConfigRefershLoading,
    setIsConnectionConfigRefershLoading,
  ] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  const refreshDBConfig = () => {
    setIsConnectionConfigRefershLoading(true);
    axios
      .put(`${baseUrl}/connection/${connectionId}/`, {})
      .then(function (response) {
        setConnectionDbConfig(response.data.db_config);
        setIsConnectionConfigRefershLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionConfigRefershLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedTableName) {
      setSelectedTableName(
        connectionDbConfig ? Object.keys(connectionDbConfig)[0] : ""
      );
    }
  }, [connectionDbConfig]);

  const onClick: MenuProps["onClick"] = (e: any) => {
    setSelectedTableName(e.key);
  };

  const updateConnectionDbConfigValue = (
    column: string,
    key: string,
    value: any
  ) => {
    const tmpConnectionDbConfig = { ...connectionDbConfig };
    var columnDetails: any = [...tmpConnectionDbConfig[selectedTableName]].find(
      (row) => row.name === column
    );
    columnDetails[key] = value;
    console.log(columnDetails, tmpConnectionDbConfig);
    setConnectionDbConfig(tmpConnectionDbConfig);
  };

  const updateConnectionDbConfig = () => {
    setIsConnectionDbConfigUpdating(true);
    axios
      .put(`${baseUrl}/connection/${connectionId}/update-db-config/`, {
        db_config: connectionDbConfig,
        disabled_tables: disabledTables,
        db_summary_json: dbSummaryJson,
      })
      .then(function (response) {
        setIsConnectionDbConfigUpdating(false);
        openNotificationWithIcon("success", response.data.message);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionDbConfigUpdating(false);
        openNotificationWithIcon("error", error.response.data.error);
      });
  };

  const columns: ColumnsType<IConnectionDbColumnConfig> = [
    {
      title: "Column Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ellipsis: true,
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      width: 150,
      render: (enabled, { name }) => (
        <>
          <Switch
            key={`${selectedTableName}-${name}`}
            defaultChecked={enabled !== undefined ? enabled : true}
            onChange={(value) => {
              updateConnectionDbConfigValue(name, "enabled", value);
            }}
          />
        </>
      ),
    },
    // {
    //   title: "Updated Name",
    //   dataIndex: "name",
    //   key: "name",
    //   ellipsis: true,
    //   render: (text) => (
    //     <>
    //       <Input disabled value={text} />
    //     </>
    //   ),
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (text, { name }) => (
        <>
          <TextArea
            value={text}
            onChange={(e) => {
              updateConnectionDbConfigValue(
                name,
                "description",
                e.target.value
              );
            }}
          />
        </>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Primary Key",
      dataIndex: "is_primary_key",
      key: "is_primary_key",
      width: 120,
      render: (is_primary_key) => <>{is_primary_key ? "Yes" : "No"}</>,
    },
    {
      title: "Foreign Key",
      dataIndex: "foreign_key",
      key: "foreign_key",
      width: 120,
    },

  ];
  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node!.getBoundingClientRect();
    setTableHeight(window.innerHeight - top - 120);
  }, [ref]);

  return (
    <>
      {contextHolder}
      {connectionDbConfig && (
        <>
          {isConnectionConfigLoading ? (
            <div
              style={{
                display: "flex",
                height: "-webkit-fill-available",
                justifyContent: "center",
              }}
            >
              <Spin style={{ alignSelf: "center" }} />
            </div>
          ) : (
            <>
              <div style={{ height: "calc(100% - 72px)", marginRight: "16px" }}>
                <div
                  style={{ display: "flex", gap: 10, justifyContent: "right", marginBottom: "8px" }}
                >
                  <Button disabled>Generate Description</Button>
                  {/* <Button
                    type="primary"
                    loading={isConnectionConfigRefershLoading}
                    onClick={refreshDBConfig}
                  >
                    Refresh DB
                  </Button> */}
                  <Button
                    type="primary"
                    loading={isConnectionDbConfigUpdating}
                    onClick={updateConnectionDbConfig}
                  >
                    Update
                  </Button>
                </div>
                <div style={{ display: "flex", height: "calc(100% - 40px)", }}>
                  <div
                    style={{ height: "100%", overflowX: 'auto', marginRight: "8px" }}
                  >
                    <Menu
                      onClick={onClick}
                      style={{ minWidth: 156, borderRight: 0 }}
                      selectedKeys={[selectedTableName]}
                      mode="inline"
                      items={Object.keys(connectionDbConfig)
                        .sort()
                        ?.map((tableName) => ({
                          key: tableName,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                gap: 10,
                              }}
                            >
                              {tableName}
                              <div onClick={(e) => e.stopPropagation()}>
                                <Switch
                                  defaultChecked={
                                    disabledTables &&
                                    !disabledTables.includes(tableName)
                                  }
                                  onChange={(value) => {
                                    if (value) {
                                      var tmp = [...disabledTables];
                                      tmp = tmp.filter(
                                        (item) => item !== tableName
                                      );
                                      setDisabledTables(tmp);
                                    } else {
                                      var tmp = [...disabledTables];
                                      tmp.push(tableName);
                                      setDisabledTables(tmp);
                                    }
                                  }}
                                />
                              </div>

                            </div>
                          ),
                        }))}
                    />
                  </div>
                  <div style={{ flex: 6, display: "flex", overflow: "hidden", flexDirection: "column", }}>
                    <TextArea
                      key={selectedTableName}
                      placeholder={`${selectedTableName} table description`}
                      value={
                        dbSummaryJson
                          ? dbSummaryJson[selectedTableName]?.description
                          : ""
                      }
                      style={{ marginBottom: "8px" }}
                      onChange={(e) => {
                        var tmpDbSummaryJson = dbSummaryJson
                          ? { ...dbSummaryJson }
                          : { [selectedTableName]: { description: "" } };
                        tmpDbSummaryJson[selectedTableName].description =
                          e.target.value;
                        setDbSummaryJson(tmpDbSummaryJson);
                      }}
                    />

                    <Table
                      style={{ height: "calc(100% - 40px)" }}
                      columns={columns}
                      dataSource={
                        selectedTableName
                          ? connectionDbConfig[selectedTableName]
                          : []
                      }
                      loading={isConnectionConfigLoading}
                      ref={ref}
                      scroll={{ x: "max-content", y: tableHeight }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
