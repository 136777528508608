import cruxLogo from "./assets/crux_big.svg";
import cruxSmalllogo from "./assets/crux_small.svg";

import nearLogo from "./assets/near-logo.svg";
import nearSmalllogo from "./assets/near-small-logo.png";

import pixisLogo from "./assets/pixis_black_logo4x-80.jpg";
import pixisSmalllogo from "./assets/pixis-logo-small.webp";

import leucineLogo from "./assets/leucine-logo.svg";
import leucineSmalllogo from "./assets/s-logo.jpeg";

interface CommonConfig { }
interface CommonConfig { }

interface ScenarioConfig {
  REACT_APP_KEYCLOAK_URL: string;
  REACT_APP_KEYCLOAK_REALM: string;
  REACT_APP_KEYCLOAK_REALM_CLIENT_ID: string;
  REACT_APP_API_BASE_URL: string;
  REACT_APP_UI_BASE_URL: string;
  REACT_APP_SMALL_LOGO: any;
  REACT_APP_BIG_LOGO: any;
  REACT_APP_CHAT_LOGO: any;
  REACT_APP_DEMO_CONNECTIONS?: any
  REACT_APP_PRODUCT_FRUITS_KEY?: string
}

interface ScenarioConfigs {
  [scenario: string]: ScenarioConfig;
}

const commonConfig: CommonConfig = {
  REACT_APP_DEMO_CONNECTIONS: ["2c8538c7-da3f-4339-a749-c47a7e88b18d", "b52a969f-3774-49a9-b81b-80f1861063d5"],
};

const scenarioConfigs: ScenarioConfigs = {
  app: {
    REACT_APP_KEYCLOAK_URL: "https://idp.getcrux.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "crux",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "https://admin.api.getcrux.ai/api",
    // REACT_APP_API_BASE_URL: "http://127.0.0.1:8000/api",
    REACT_APP_UI_BASE_URL: "https://app.getcrux.ai",
    REACT_APP_SMALL_LOGO: cruxSmalllogo,
    REACT_APP_BIG_LOGO: cruxLogo,
    REACT_APP_CHAT_LOGO: cruxSmalllogo,
  }, qa: {
    REACT_APP_KEYCLOAK_URL: "https://idp.getcrux.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "crux",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "http://127.0.0.1:8000/api",
    // REACT_APP_API_BASE_URL: "https://qa.api.getcrux.ai/api",
    REACT_APP_UI_BASE_URL: "https://app.getcrux.ai",
    REACT_APP_SMALL_LOGO: cruxSmalllogo,
    REACT_APP_BIG_LOGO: cruxLogo,
    REACT_APP_CHAT_LOGO: cruxSmalllogo,
    REACT_APP_PRODUCT_FRUITS_KEY: "nRiHDbgC7tKEV1e3"
  },
  near: {
    REACT_APP_KEYCLOAK_URL: "https://idp.getcrux.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "crux",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "https://admin.api.getcrux.ai/",
    // REACT_APP_API_BASE_URL:'http://127.0.0.1:8000/',
    REACT_APP_UI_BASE_URL: "https://app.getcrux.ai",
    REACT_APP_SMALL_LOGO: nearSmalllogo,
    REACT_APP_BIG_LOGO: nearLogo,
    REACT_APP_CHAT_LOGO: cruxSmalllogo,
  },
  pixis: {
    REACT_APP_KEYCLOAK_URL: "https://login-prism.pixis.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "pixis",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "https://pixis.api.getcrux.ai/",
    // REACT_APP_API_BASE_URL:'http://127.0.0.1:8000/',
    REACT_APP_UI_BASE_URL: "https://prism.pixis.ai",
    REACT_APP_SMALL_LOGO: pixisSmalllogo,
    REACT_APP_BIG_LOGO: pixisLogo,
    REACT_APP_CHAT_LOGO: pixisSmalllogo,
  },
  leucine: {
    REACT_APP_KEYCLOAK_URL: "https://leucine.idp.getcrux.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "crux",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "https://leucine.admin.api.getcrux.ai/",
    // REACT_APP_API_BASE_URL:'http://127.0.0.1:8000/'
    REACT_APP_UI_BASE_URL: "https://leucine.admin.getcrux.ai",
    REACT_APP_SMALL_LOGO: leucineSmalllogo,
    REACT_APP_BIG_LOGO: leucineLogo,
    REACT_APP_CHAT_LOGO: cruxSmalllogo,
  }, dev: {
    REACT_APP_KEYCLOAK_URL: "https://idp.getcrux.ai/auth/",
    REACT_APP_KEYCLOAK_REALM: "crux",
    REACT_APP_KEYCLOAK_REALM_CLIENT_ID: "react-auth",
    REACT_APP_API_BASE_URL: "https://dev.api.getcrux.ai/api",
    REACT_APP_UI_BASE_URL: "https://app.getcrux.ai",
    REACT_APP_SMALL_LOGO: cruxSmalllogo,
    REACT_APP_BIG_LOGO: cruxLogo,
    REACT_APP_CHAT_LOGO: cruxSmalllogo,
  }
};

export function getConfig(scenario: string): CommonConfig & ScenarioConfig {
  const scenarioConfig = scenarioConfigs[scenario] || {};
  return { ...commonConfig, ...scenarioConfig };
}
