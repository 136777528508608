import axios from "axios";
import { RefObject, useEffect, useRef, useState } from "react";

import { ConfigProvider, Divider, Spin, Select, Modal, Skeleton, Tooltip } from "antd";
import { InteractSidebar } from "./components/InteractSidebar";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IConnectionProps } from "../database/Database";
import { baseUrl } from "../database/NewConnection";
import { useKeycloak } from "@react-keycloak/web";
import { IUserFeedbackProps } from "./components/Feedback";
import ChatV2 from "./components/Chatv2";
import rightOutline from "../../assets/icons/rightOutline.svg"
import settingIcon from "../../assets/icons/setting.svg"
import DBShortView from "./components/DBShortView";
import { configToUse } from "../../Keycloak";

function formatNumber(value: number) {
  // Check if the value is an integer
  if (Number.isInteger(value)) {
    return value.toString(); // Return integer value as string
  }

  // If 0 < VAL < 1, display 2 significant figures
  if (Math.abs(value) > 0 && Math.abs(value) < 1) {
    return value.toPrecision(2);
  }

  // Otherwise, display 2 decimal places by default
  return value.toFixed(2);
}

export interface IQuestionAnswerPropsV2 {
  id?: number;
  questionId?: string;
  user?: string;
  question?: string;
  type?: string;
  answer?: any;
  workflow_response?: string;
  graphs?: any;
  assumptions?: string;
  query?: string;
  sqlQueryExplanation?: string;
  data?: any;
  isLoading?: boolean;
  selectedTab?: string;
  clarificationData?: any;
  finalConfirmationData?: any;
  feedback?: IUserFeedbackProps;
  chartSelection?: string;
  graphFetching?: boolean;
  summaryFetching?: boolean;
  timestamp?: Date;
  query_variables?: any;
}

const Interact = () => {
  const { chatId, connectionConfigId } = useParams();
  console.log(connectionConfigId)
  const navigate = useNavigate();
  const controllerRef = useRef(new AbortController());
  const [showChat, setShowChat] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState({});
  const [chatList, setChatList] = useState<any>([]);
  const [questionAnswerListV2, setQuestionAnswerListV2] = useState<
    Array<IQuestionAnswerPropsV2>
  >([]);
  const [isChatListLoading, setIsChatListLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  const [sampleQuestionList, setSampleQuestionList] = useState([]);
  const [fetchSummary, setFetchSummary] = useState<boolean>(false);
  const [fetchGraph, setFetchGraph] = useState<boolean>(false);

  const updateTab = (value: Array<IQuestionAnswerPropsV2>) => {
    setQuestionAnswerListV2(value);
  };
  const [isConnectionListLoading, setIsConnectionListLoading] = useState(true);
  const [connectionList, setConnectionList] = useState<Array<IConnectionProps>>(
    []
  );
  const [next, setNext] = useState(null);
  const [totalChatListLength, setTotalChatListLength] = useState(0);
  const [FollowUpQuestions, setFollowUpQuestions] = useState<Array<string>>([]);
  const [latestQueryAgentId, setLatestQueryAgentId] = useState<string>("");
  const [isFollowUpQsLoading, setIsFollowUpQsLoading] = useState<boolean>(false);

  const { keycloak } = useKeycloak();

  useEffect(() => {
    axios
      .get(`${baseUrl}/connection/all/`)
      .then(function (response) {
        setConnectionList(response.data);
        if (response.data.length > 0 && !chatId && !connectionConfigId) {
          navigate("/interact/" + response.data[0].id);
        }
        setIsConnectionListLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionListLoading(false);
      });
  }, []);

  useEffect(() => {
    if (fetchSummary) {
      var tempQuestionAnswerListV2 = [...questionAnswerListV2];
      var ques = questionAnswerListV2.filter((row) => row.summaryFetching)[0];
      axios
        .get(
          `${baseUrl}/chat/v3/${connectionConfigId}/question/${ques.questionId}/summary/`
        )
        .then((response) => {
          console.log("called");
          ques.answer = response.data.response;
          ques.summaryFetching = false;
          console.log(tempQuestionAnswerListV2);
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
        })
        .catch((error) => {
          ques.summaryFetching = false;
          console.log(error);
        });
      setFetchSummary(false);
    }
  }, [fetchSummary]);

  useEffect(() => {
    if (fetchGraph) {
      var tempQuestionAnswerListV2 = [...questionAnswerListV2];
      var ques = questionAnswerListV2.filter((row) => row.graphFetching)[0];
      axios
        .get(
          `${baseUrl}/chat/v3/${connectionConfigId}/question/${ques.questionId}/graph/`
        )
        .then((response) => {
          console.log("called", response.data);
          var graph_ = JSON.parse(response.data.response.graph_config);
          graph_.config = graph_.chart_config;
          delete graph_.chart_config;
          ques.graphs = ques.graphs ? ques.graphs : [];
          ques.graphs.push(graph_);
          ques.graphFetching = false;
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
        })
        .catch((error) => {
          console.log(error);
        });
      setFetchGraph(false);
    }
  }, [fetchGraph]);

  useEffect(() => {
    if (connectionConfigId) {
      setIsChatListLoading(true);
      setQuestionAnswerListV2([]);
      axios
        .get(`${baseUrl}/chat/v3/${connectionConfigId}/all/?items_per_page=15`)
        .then(function (response) {
          setTotalChatListLength(response.data.count);
          setNext(response.data.next);
          setChatList(response.data.results);
          setSampleQuestionList(response.data.sample_question_list);
          setIsChatListLoading(false);
        });
    }
  }, [connectionConfigId]);



  useEffect(() => {
    setFollowUpQuestions([]);
    console.log(chatId);
    if (chatId && !isNewChat) {
      setIsChatLoading(true);
      axios
        .get(`${baseUrl}/chat/v3/${connectionConfigId}/chat-history/${chatId}/`)
        .then(function (response) {
          setIsChatLoading(false);
          var responseData: Array<any> = response.data;
          setQuestionAnswerListV2(
            responseData.map((row, i) => {
              setFollowUpQuestions([]);
              var questionAnswerV2: IQuestionAnswerPropsV2 = {};
              questionAnswerV2.timestamp = new Date(row.timestamp);
              questionAnswerV2.questionId = row.id;
              questionAnswerV2.user = row.user;
              questionAnswerV2.feedback = row.feedback;
              questionAnswerV2.question = row.question;
              questionAnswerV2.type = row.type;
              if (row.type === "completion") {
                console.log(row.response);
                questionAnswerV2.answer = row.response;
              } else if (row.type === "clarification") {
                questionAnswerV2.clarificationData = JSON.parse(row.response);
                if (i !== responseData.length - 1) {
                  console.log(responseData.length - 1, i);
                  questionAnswerV2.clarificationData.options = [];
                }
              } else if (row.type === "final_confirmation") {
                questionAnswerV2.finalConfirmationData = JSON.parse(
                  row.response
                );
                if (i !== responseData.length - 1) {
                  questionAnswerV2.finalConfirmationData.confirmed = true;
                }
              } else if (row.type === "query") {
                questionAnswerV2.query = row.response.sql;
                questionAnswerV2.sqlQueryExplanation = row.response.explanation;
                questionAnswerV2.query_variables = row.response?.query_variables
                let data = JSON.parse(row.response.data);
                data = data.map((value: any) => {
                  let keys = Object.keys(value)
                  keys.forEach((key) => {
                    if (!Number.isNaN(Number(value[key]))) {
                      value[key] = formatNumber(Number(value[key]))
                    }
                  })
                  return value
                })
                questionAnswerV2.data = data

                if (questionAnswerV2.data && questionAnswerV2.data.length > 0) {
                  questionAnswerV2.answer = row.response.summary
                    ? row.response.summary
                    : undefined;
                } else {
                  questionAnswerV2.answer = `<span>We don't have data available. Please try something else.</span>
                    <span>There are three possibilities:</span>
                    <ol style="margin: 0px">
                        <li>You might be looking for something which is stored slightly differently in the database.</li>
                        <li>Database don't have the data you are looking for.</li>
                        <li>The output of your query is NULL.</li>
                    </ol>`;
                }
                if (row.response.follow_up_questions) {
                  console.log(row.response.follow_up_questions);
                  setFollowUpQuestions(row.response.follow_up_questions);
                  setLatestQueryAgentId(row.response.agentId);
                }
                if (row.response.graph) {
                  if (Array.isArray(row.response.graph)) {
                    var graphs = [];

                    for (var i = 0; i < row.response.graph.length; i++) {
                      var graph = JSON.parse(row.response.graph[i]);
                      if (graph.chart_config) {
                        graph.config = graph.chart_config;
                        delete graph.chart_config;
                      }
                      graph.is_editing = false;
                      graphs.push(graph);
                    }
                    questionAnswerV2.graphs = graphs;
                  } else {
                    var graph = JSON.parse(row.response.graph);
                    graph.config = graph.chart_config;
                    delete graph.chart_config;
                    questionAnswerV2.graphs = [graph];
                  }
                }
              }
              questionAnswerV2.isLoading = false;
              questionAnswerV2.summaryFetching = false;
              questionAnswerV2.graphFetching = false;
              questionAnswerV2.selectedTab = "2";
              return questionAnswerV2;
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setIsNewChat(false);
  }, [chatId]);

  const callExampleQuestion = (exampleQuestion: string) => {
    getData(exampleQuestion);
  };

  useEffect(() => {
    console.log(FollowUpQuestions);
  }, [FollowUpQuestions]);

  const getFollowUpQuestions = (agentId: string) => {
    setIsFollowUpQsLoading(true);
    if (chatId && connectionConfigId) {
      axios
        .post(
          `${baseUrl}/chat/v3/${connectionConfigId}/${chatId}/get-followup-questions/`,
          {
            id: agentId,
          }
        )
        .then((response: any) => {
          setFollowUpQuestions([...FollowUpQuestions, ...response.data]);
          setIsFollowUpQsLoading(false);
        })
        .catch((error) => {
          setIsFollowUpQsLoading(false);
          console.log(error);
        });
    }
  };

  const getData = (question: string) => {
    setFollowUpQuestions([]);
    setIsLoading(true);
    var idV2 = questionAnswerListV2.length + 1;
    var tempQuestionAnswerListV2 = [...questionAnswerListV2];
    if (idV2 > 1) {
      if (tempQuestionAnswerListV2[idV2 - 2].type === "clarification") {
        tempQuestionAnswerListV2[idV2 - 2].clarificationData.options = [];
      } else if (
        tempQuestionAnswerListV2[idV2 - 2].type === "final_confirmation"
      ) {
        tempQuestionAnswerListV2[idV2 - 2].finalConfirmationData.confirmed =
          true;
      }
    }
    tempQuestionAnswerListV2.push({
      id: idV2,
      user: keycloak.idTokenParsed?.preferred_username,
      question: question,
      data: [],
      isLoading: true,
      selectedTab: "2",
    });
    questionAnswerListV2.push({
      id: idV2,
      user: keycloak.idTokenParsed?.preferred_username,
      question: question,
      data: [],
      isLoading: true,
      selectedTab: "2",
    });
    setQuestionAnswerListV2(tempQuestionAnswerListV2);
    getQuery(idV2, question);
  };

  const getQuery = (id: number, ques: string) => {
    // var question = inputVal;
    // setInputVal("");
    // setQuestion(question);
    setShowChat(true);
    const controller = new AbortController();
    controllerRef.current.abort(); // Cancel previous request
    controllerRef.current = controller; // Update the current AbortController
    const url = configToUse.REACT_APP_DEMO_CONNECTIONS.includes(connectionConfigId) ? `${baseUrl}/chat/v0/${connectionConfigId}/question/` : `${baseUrl}/chat/v3/${connectionConfigId}/question/`
    axios
      .post(
        url,
        {
          input: ques,
          chatId: chatId,
        },
        {
          signal: controller.signal,
          timeout: 300000,
        }
      )
      .then(function (response) {
        var tempQuestionAnswerListV2 = [...questionAnswerListV2];
        var questionAnswerV2 = tempQuestionAnswerListV2.filter(
          (row) => row.id === id
        )[0];
        questionAnswerV2.questionId = response.data.id;
        questionAnswerV2.timestamp = new Date(response.data.timestamp);
        questionAnswerV2.user = response.data.user;
        questionAnswerV2.type = response.data.type;
        if (response.data.type === "completion") {
          questionAnswerV2.answer = response.data.response;
        } else if (response.data.type === "clarification") {
          questionAnswerV2.clarificationData = JSON.parse(
            response.data.response
          );
        } else if (response.data.type === "final_confirmation") {
          questionAnswerV2.finalConfirmationData = JSON.parse(
            response.data.response
          );
          questionAnswerV2.finalConfirmationData.confirmed = false;
        } else if (response.data.type === "query") {
          questionAnswerV2.query = response.data.response.sql;
          questionAnswerV2.sqlQueryExplanation =
            response.data.response.explanation;
          questionAnswerV2.query_variables = response.data.response.query_variables
          let data = JSON.parse(response.data.response.data);
          data = data.map((value: any) => {
            let keys = Object.keys(value)
            keys.forEach((key) => {
              if (!Number.isNaN(Number(value[key]))) {
                value[key] = formatNumber(Number(value[key]))
              }
            })
            return value
          })
          questionAnswerV2.data = data


          if (questionAnswerV2.data && questionAnswerV2.data.length > 0) {
            questionAnswerV2.summaryFetching = true;
            questionAnswerV2.graphFetching = true;
            setFetchSummary(true);
            setFetchGraph(true);
            getFollowUpQuestions(response.data.agentId);
            setLatestQueryAgentId(response.data.agentId);
          } else {
            questionAnswerV2.answer = `<span>We don't have data available. Please try something else.</span>
              <span>There are three possibilities:</span>
              <ol style="margin: 0px">
                  <li>You might be looking for something which is stored slightly differently in the database.</li>
                  <li>Database don't have the data you are looking for.</li>
                  <li>The output of your query is NULL.</li>
              </ol>`;
          }
        }
        questionAnswerV2.isLoading = false;
        // console.log(tempQuestionAnswerList, tempQuestionAnswerListV2);
        // setQuestionAnswerList(tempQuestionAnswerList);
        setQuestionAnswerListV2(tempQuestionAnswerListV2);
        if (response.data.chat) {
          setIsNewChat(true);
          chatList.unshift(response.data.chat);
          navigate(`/interact/${connectionConfigId}/${response.data.chat.id}`);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
          setIsLoading(false);
        } else {
          console.log(error);
          var tempQuestionAnswerListV2 = [...questionAnswerListV2];
          var questionAnswerV2 = tempQuestionAnswerListV2.filter(
            (row) => row.id === id
          )[0];
          questionAnswerV2.answer = "Error... Try again";
          questionAnswerV2.isLoading = false;
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
          setIsLoading(false);
        }
      });
  };
  // Interact Sidebar State Handler
  const [activeInteractSidebar, setActiveInteractSidebar] = useState(true);
  const toggleInteractSidebar = () => setActiveInteractSidebar((pre) => !pre)

  // Database Config Setting Handlers
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInput, setModalInput] = useState("");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // Database Config Setting Handlers



  return (
    <ConfigProvider>
      {

        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {/* Interact Sidebar - Start */}
          <div
            style={{
              height: "100%",
              width: "220px",
              margin: "0",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              transition: "0.5s",
              marginLeft: activeInteractSidebar ? "0" : "-220px",
              minWidth: "220px"
            }}
          >
            <InteractSidebar
              connectionConfigId={connectionConfigId}
              chatId={chatId}
              setQuestionAnswerList={updateTab}
              controllerRef={controllerRef}
              chatList={chatList}
              isChatListLoading={isChatListLoading}
              connectionList={connectionList}
              isConnectionListLoading={isConnectionListLoading}
              setChatList={setChatList}
              next={next}
              setNext={setNext}
              totalChatListLength={totalChatListLength}
              setTotalChatListLength={setTotalChatListLength}
              toggleInteractSidebar={toggleInteractSidebar}
            />
          </div>

          <Divider
            type="vertical"
            style={{
              height: "100%",
              margin: "0px",
              borderColor: "#EAECF0",
              borderWidth: "1px"
            }}
          />
          {!activeInteractSidebar && <div onClick={toggleInteractSidebar} style={{ height: "100%", display: "flex", alignItems: "center", cursor: "pointer" }}>
            <Tooltip title="Open Sidebar">
              <img src={rightOutline} alt="" />
            </Tooltip>
          </div>}

          {/* Interact Sidebar - End */}

          {/* Interact Main Screen  */}
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
            }}
          >

            {/* Database Config Setting - Start */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 5px", height: "52px", marginTop: "0.5rem" }}>
              <Select
                className="selectdb"
                value={isConnectionListLoading ? "" : connectionConfigId}
                loading={isConnectionListLoading}
                onChange={(e) => {
                  navigate(`/interact/${e}`);
                }}
                options={connectionList.map((row) => ({
                  value: row.id,
                  label: row.name,
                }))}
                style={{ minWidth: "200px", textAlign: "center" }}
              />
              <Tooltip title="Database Details">
                <button className="rm-hover" style={{
                  marginLeft: "10px",
                  padding: "5px",
                  borderRadius: "6px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: 'center',
                  background: "white",
                  border: "1px solid #dcdde0",
                  cursor: "pointer"

                }}
                  onClick={() => setIsModalOpen(pre => !pre)}>
                  <img src={settingIcon} alt="" />
                </button>
              </Tooltip>
              <Modal
                centered
                width={900}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                zIndex={6}
                title="Database Details"
              >
                <DBShortView
                  modalInput={modalInput}
                  getData={getData}
                  setIsModalOpen={setIsModalOpen}
                  setModalInput={setModalInput}
                  showCheckBox={true}
                />
              </Modal>

            </div>
            {/* Database Config Setting - End */}



            {/* Chat - Start */}
            <div
              style={{
                height: "calc(100vh - 52px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {connectionConfigId && (
                <>
                  {isChatLoading || (!chatId && isChatListLoading) ? (
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        padding: "12px",
                        gap: 20,
                        // border: "2px solid red"
                      }}
                    >
                      <div style={{ marginLeft: "auto", width: "60%" }}>
                        <Skeleton.Input active block />
                      </div>
                      <Skeleton active style={{ width: "60%" }} />
                      <div style={{ marginLeft: "auto", width: "40%" }}>
                        <Skeleton.Input active block />
                      </div>
                      <Skeleton active style={{ width: "50%" }} />
                      <div style={{ marginLeft: "auto", width: "50%" }}>
                        <Skeleton.Input active block />
                      </div>
                      <Skeleton active style={{ width: "70%" }} />
                    </div>
                  ) : (
                    <ChatV2
                      key={chatId}
                      questionAnswerListV2={questionAnswerListV2}
                      setQuestionAnswerListV2={setQuestionAnswerListV2}
                      getData={getData}
                      callExampleQuestion={callExampleQuestion}
                      sampleQuestionList={sampleQuestionList}
                      isLoading={isLoading}
                      FollowUpQuestions={FollowUpQuestions}
                    />
                  )}
                </>
              )}
            </div>



          </div>
        </div>
        // )
      }
    </ConfigProvider >
  );
};
export default Interact;
