import { useNavigate, useParams } from "react-router-dom";
import { configToUse } from "../../../Keycloak";
import { useEffect, useRef, useState } from "react";
import { InsightFeedProps } from "../Insight";
import axios from "axios";
import { ArrowRightOutlined, BulbOutlined, LeftOutlined } from "@ant-design/icons";
import { Radio, Button, Avatar, notification, Skeleton } from "antd";
import styles from "./InsightDetailPage.module.css";
import InsightTags from "../components/InsightTags";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/Helpers";
import Lottie from "lottie-react";
import loaderAnimation from "../../../assets/Loader.json";
import cruxLogo from "../../../assets/crux_small.svg";
import starIcon from "../../../assets/star.svg";
import Graph from "../components/Graph";
import Feedback from "../components/Feedback";
import { NotificationType } from "../../database/DatabaseDetail/DBConfig";
import { BoldText } from "../Insight";

const baseUrl = configToUse.REACT_APP_API_BASE_URL;

interface InsightDetail extends InsightFeedProps {
  config: any;
  report: string;
  chat_history: {
    id: string;
    user: string;
    question: string;
    response: string | boolean;
  }[];
}

const EXPERIMENTAL_FEEDBACK = false;
const SAMPLE_QUESTIONS = ["Summarize this report for me", "Provide more details on the potential benefits or consequences of the suggested actions", "How will the success or impact of the suggested actions be measured?"]
function ChatEntry(data: InsightDetail["chat_history"][0]) {
  const quesRef = useRef<HTMLDivElement | null>(null);
  const responseRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = (entries: any[]) => {
    entries.forEach((entry) => {
      const opacity = entry.intersectionRatio;
      entry.target.style.opacity = opacity;
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    });
    let closureQuesRef: Element;
    let closureResRef: Element;
    if (quesRef.current) {
      closureQuesRef = quesRef.current;
      observer.observe(closureQuesRef);
    }
    if (responseRef.current) {
      closureResRef = responseRef.current;
      observer.observe(closureResRef);
    }
    return () => {
      if (closureQuesRef) {
        observer.unobserve(closureQuesRef);
      }
      if (closureResRef) {
        observer.unobserve(closureResRef);
      }
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
      <div ref={quesRef} style={{ alignSelf: "flex-end" }}>
        <div className={styles.self}>{data.question}</div>
      </div>
      <div
        ref={responseRef}
        style={{
          alignSelf: "flex-start",
          display: "flex",
        }}
      >
        <Avatar src={cruxLogo} shape="square" />
        <div
          className={styles.bot}
          style={{ width: data.response === false ? "10%" : "100%" }}
        >
          {data.response === false ? (
            <div>
              <Lottie
                animationData={loaderAnimation}
                rendererSettings={{ preserveAspectRatio: "none" }}
                style={{ margin: "-20px -25px" }}
              />
            </div>
          ) : (
            data.response
          )}
        </div>
      </div>
    </div>
  );
}

export default function InsightDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const [insightData, setInsightData] = useState<InsightDetail>(
    {} as InsightDetail
  );

  const [isLoading, setIsLoading] = useState(true);
  const [graphOptions, setGraphOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [chatHistory, setChatHistory] = useState<InsightDetail["chat_history"]>(
    []
  );
  const [chatQuery, setChatQuery] = useState("");
  const [graphView, setGraphView] = useState<string>("");

  const [api, contextHolder] = notification.useNotification();

  const showToastMessage = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      placement: "topRight",
    });
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const fetchData = () => {
    axios.get(`${baseUrl}/insight/${id}`).then((response) => {
      const res = response.data as InsightDetail;
      const newOptions = Object.keys(res.data_for_graph).map((ele) => {
        return {
          label: capitalizeFirstLetter(ele.replaceAll("_", " ")),
          value: ele,
        };
      });
      setInsightData(res);
      setGraphOptions(newOptions);
      setGraphView(newOptions[0].value);
      setChatHistory(res.chat_history);
      setIsLoading(false);
    }).catch((error) => showToastMessage("error", error.message));
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory.length]);

  const getGraphData = () => {
    return insightData.data_for_graph[graphView]?.map(
      (points: { [key: string]: string }) => {
        return {
          [insightData.frequency.frequency_label]: getFormattedDate(
            points[insightData.frequency.frequency_label]
          ),
          [graphView]: parseFloat(points[graphView] as string),
        };
      }
    ) ?? [];
  };

  const renderMessages = () => {
    if (isLoading) return <Skeleton paragraph={{ rows: 16 }} active />
    if (chatHistory.length > 0) {
      return chatHistory.map((ele) => {
        return <ChatEntry {...ele} key={ele.id} />;
      });
    }
    return (
      <div className={styles.emptyChat}>
        <div className={styles.emptyChatHeader}>
          <img src={starIcon} alt="chat" />
          <span>Chat with your report</span>
        </div>
        <div className={styles.quesSec}>
          <div className={styles.examples}>
            <BulbOutlined color="#F2F3FF" />
            <div>Examples</div>
          </div>
          {SAMPLE_QUESTIONS.map((ele, idx) => (
            <div
              key={idx}
              onClick={() => askQuestion(ele)}
              className={styles.sampleQues}
            >
              {`"${ele}"`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const askQuestion = (input: string) => {
    let newChatHistory = [
      ...chatHistory,
      { id: "new", user: "user", question: input, response: false },
    ];
    setChatQuery("");
    setChatHistory(newChatHistory);
    axios
      .post(`${baseUrl}/insight/${id}/question`, { input })
      .then((response) => {
        const newChat = response.data;
        const newChatRes = [...chatHistory, { ...newChat, question: input }];
        setChatHistory(newChatRes);
      });
  };

  const submitFeedback = (
    para: string | undefined,
    index: string,
    feedback: {}
  ) => {
    const prevData = insightData.feedback;
    const summary = prevData?.summary;
    const newSummary = { ...summary, [index]: { para, feedback } };
    const data = { ...prevData, summary: newSummary };

    axios
      .post(`${baseUrl}/insight/${id}/feedback`, { ...data })
      .then((response) => {
        showToastMessage("success", response.data.message);
        fetchData();
      })
      .catch((error) => {
        showToastMessage("error", error.response.data.message);
      });
  };

  const getReport = () => {
    const textToUse = insightData?.report.replace("Insights Report:\n\n", "");
    const summaryObj = insightData.feedback?.summary ?? {};

    if (EXPERIMENTAL_FEEDBACK) {
      return textToUse.split("\n").map((ele, idx) => {
        if (!ele.length) {
          return <p></p>;
        }

        return (
          <p>
            <span style={{ marginRight: "8px" }}>{BoldText(ele)}</span>
            <Feedback
              id={`${idx}`}
              para={ele}
              {...(idx in summaryObj && summaryObj[idx].feedback)}
              in_detail={EXPERIMENTAL_FEEDBACK}
              onSubmit={submitFeedback}
              key={id + `${idx}`}
            />
          </p>
        );
      });
    }
    return <div dangerouslySetInnerHTML={{ __html: BoldText(textToUse) }}></div>;
  };
  return (
    <div className={styles.page}>
      {contextHolder}
      <div
        style={{
          color: "#101828",
          fontSize: "24px",
          fontWeight: "600",
          marginBottom: "24px",
        }}
      >
        <LeftOutlined
          onClick={() => navigate(`..?connectionId=${insightData.config.connection}&configId=${insightData.config.id}`, { relative: "path" })}
          style={{ cursor: "pointer" }}
        />
        <span style={{ marginLeft: "8px" }}>Insights Report:</span>
      </div>
      <div className={styles.contentSection}>
        <div className={styles.report}>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 16 }} active />
          ) : (
            <>
              <div>
                <InsightTags {...insightData} />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#475467",
                  }}
                >
                  {getFormattedDate(insightData.created_at)}
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#101828",
                    margin: "4px 0px",
                  }}
                >
                  {insightData?.title.replaceAll('"', "")}
                </div>
                <div style={{ whiteSpace: "pre-line" }}>{getReport()}</div>
              </div>
              <div className={styles.graphContainer}>
                <div>
                  <Radio.Group
                    options={graphOptions}
                    optionType="button"
                    value={graphView}
                    onChange={({ target: { value } }) => setGraphView(value)}
                  />
                </div>
                {graphView.length && (
                  <Graph
                    data={getGraphData() as []}
                    xField={insightData.frequency.frequency_label}
                    yField={graphView}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.chatBox}>
          <div className={styles.messages} ref={chatBoxRef}>
            {renderMessages()}
          </div>
          <br />
          <div className={styles.inputContainer}>
            <input
              type="text"
              className={styles.input}
              placeholder="Send a message"
              value={chatQuery}
              onChange={(e) => setChatQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  askQuestion(chatQuery);
                }
              }}
            />
            <Button
              shape="circle"
              icon={<ArrowRightOutlined style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#5f5adb" }}
              onClick={() => {
                askQuestion(chatQuery);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
