import axios from "axios";
import { baseUrl } from "../../database/NewConnection";
import { useEffect, useState } from "react";
import { Button, Popover, ConfigProvider } from "antd";
import { CommentOutlined, DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import style from "../Interact.module.css"


export interface IUserFeedbackProps {
  liked?: boolean;
  disliked?: boolean;
  comment?: string;
}
interface IFeedbackProps {
  questionId: string;
  feedback: IUserFeedbackProps;
}
export const Feedback = (props: IFeedbackProps) => {
  const { feedback, questionId } = props;
  const [userFeedback, setUserFeedback] =
    useState<IUserFeedbackProps>(feedback);
  const [isLikeFeedbackLoading, setIsLikeFeedbackLoading] =
    useState<boolean>(false);
  const [isDislikeFeedbackLoading, setIsDislikeFeedbackLoading] =
    useState<boolean>(false);
  const [isCommentFeedbackLoading, setIsCommentFeedbackLoading] =
    useState<boolean>(false);

  const [comment, setComment] = useState(feedback?.comment);

  //   useEffect(() => {
  //     setUserFeedback(feedback);
  //     console.log(feedback);
  //   }, [feedback]);

  const sendLikeFeedback = () => {

    setUserFeedback({
      liked: !userFeedback?.liked,
      disliked: false,
      comment: userFeedback?.comment,
    });
    axios
      .post(`${baseUrl}/chat/v3/question/${questionId}/update-feedback/`, {
        feedback: {
          liked: !userFeedback?.liked,
          disliked: false,
          comment: userFeedback?.comment,
        },
      })
      .then(function (response) {

      })
      .catch(function (e) {

      });
  };

  const sendDislikeFeedback = () => {
    setUserFeedback({
      liked: false,
      disliked: !userFeedback?.disliked,
      comment: userFeedback?.comment,
    });
    axios
      .post(`${baseUrl}/chat/v3/question/${questionId}/update-feedback/`, {
        feedback: {
          liked: false,
          disliked: !userFeedback?.disliked,
          comment: userFeedback?.comment,
        },
      })
      .then(function (response) {

      })
      .catch(function (e) {
      });
  };

  const sendCommentFeedback = () => {
    setIsCommentFeedbackLoading(true);
    axios
      .post(`${baseUrl}/chat/v3/question/${questionId}/update-feedback/`, {
        feedback: {
          liked: userFeedback?.liked,
          disliked: userFeedback?.disliked,
          comment: comment,
        },
      })
      .then(function (response) {
        setIsCommentFeedbackLoading(false);
        setUserFeedback({
          liked: userFeedback?.liked,
          disliked: userFeedback?.disliked,
          comment: comment,
        });
      })
      .catch(function (e) {
        setIsCommentFeedbackLoading(false);
      });
  };

  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#5E5ADB",
      }
    }}>
      <div style={{ display: "flex", gap: "12px", alignSelf: "flex-end" }}>
        <Button
          icon={<LikeOutlined />}
          onClick={sendLikeFeedback}
          loading={isLikeFeedbackLoading}
          className={userFeedback && userFeedback.liked ? style.feedbackBtnActive : style.feedbackBtn}
        />
        <Button
          icon={<DislikeOutlined />}
          onClick={sendDislikeFeedback}
          loading={isDislikeFeedbackLoading}
          className={userFeedback && userFeedback.disliked ? style.feedbackBtnActive : style.feedbackBtn}


        />
        <Popover
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextArea
                style={{ width: "500px" }}
                rows={5}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <a
                style={{ alignSelf: "flex-end", marginTop: "5px" }}
                onClick={sendCommentFeedback}
              >
                Send
              </a>
            </div>
          }
          placement="topRight"
          title="Feedback"
          trigger="click"
        >
          <Button
            icon={<CommentOutlined />}
            loading={isCommentFeedbackLoading}
            className={userFeedback && userFeedback.comment ? style.feedbackBtnActive : style.feedbackBtn}
          />
        </Popover>
      </div>
    </ConfigProvider>
  );
};
