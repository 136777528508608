import { useState } from "react";
import "./Ferrous.css";
import { Modal, Divider, Button } from "antd";
import { useNavigate } from "react-router-dom";

const DEMO_LINK = "https://calendly.com/atharva-getcrux/30min?month=2024-01";
export default function Ferrous() {
  const navigate = useNavigate();
  const [modalOpen, SetModalOpen] = useState(true);
  return (
    <div className={"container"}>
      <Modal
        open={modalOpen}
        footer={null}
        closeIcon={null}
        width={400}
        centered
        className={"modalContent"}
        wrapClassName="modal-wrap"
      >
        <div className={"content"}>
          <p>Schedule Demo to view Ferrous</p>
          <span>
            Schedule a call with the team to experience a full walkthrough of
            Ferrous.
          </span>
        </div>
        <Divider />
        <div className={"buttonContainer"}>
          <Button
            onClick={() => navigate("/interact")}
            className={"basicButton"}
            size="large"
          >
            Go back
          </Button>
          <Button
            onClick={() => window.open(DEMO_LINK, "__blank")}
            className={"basicButton primaryButton"}
            size="large"
          >
            Schedule
          </Button>
        </div>
      </Modal>
    </div>
  );
}
