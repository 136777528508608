import { useState } from "react";
import { AgentStatus, AgentType } from "./Chatv2";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightOutlined,
  LoadingOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import styles from "./Accordion.module.css";
import { Slider } from "./Slider";
import { Table } from "antd";
import { formatString } from "./Chart";
import CodeBlock from "../../utils/CodeBlock";

interface AccordionProps {
  title: string;
  status: AgentStatus;
  content: any;
  type: string;
}

const TABS = [
  { name: "Data", value: "data", icon: <FileTextOutlined /> },
  { name: "Query", value: "query", icon: "</>" },
];

export default function Accordion(props: AccordionProps) {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0].value);

  const getStatusIcon = (status: AgentStatus) => {
    switch (status) {
      case AgentStatus.COMPLETED:
        return (
          <CheckCircleFilled style={{ color: "#079455", fontSize: "16px" }} />
        );
      case AgentStatus.FAILED:
        return (
          <CloseCircleFilled style={{ color: "#C62828", fontSize: "16px" }} />
        );
      case AgentStatus.IN_PROGRESS:
        return (
          <LoadingOutlined style={{ color: "#5e5adb", fontSize: "16px" }} />
        );
    }
  };

  const getContent = (type: AgentType, obj: any) => {
    if (type === AgentType.QUERY) {
      if (Object.keys(obj).length > 0) {
        return (
          <>
            <Slider
              buttons={TABS}
              defaultValue={selectedTab}
              onValueChange={(v: string) => {
                setSelectedTab(v);
              }}
            />
            {selectedTab === "data" &&
              obj.data &&
              (JSON.parse(obj.data).length > 0 ? (
                <Table
                  className={styles.tableStyle}
                  style={{ maxHeight: "600px" }}
                  columns={Object.keys(JSON.parse(obj.data)[0]).map((v) => ({
                    title: formatString(v),
                    dataIndex: v,
                    ellipsis: true,
                  }))}
                  dataSource={JSON.parse(obj.data)}
                  // scroll={{ x: "max-content", y: `${20 * 50}px` }}
                />
              ) : (
                <div
                  style={{
                    color: "#344054",
                    marginTop: "4px",
                    marginLeft: "4px",
                  }}
                >
                  No data available....
                </div>
              ))}
            {selectedTab === "query" && (
              <div>
                <CodeBlock language="sql" code={obj.sql || ""} />
                <div>
                  <div
                    style={{
                      color: " #101828)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Explanation:
                  </div>
                  <div className={styles.normaltext}>{obj.explanation}</div>
                </div>
              </div>
            )}
          </>
        );
      } else {
        return <span>No data</span>;
      }
    }
    if (type === AgentType.CODE) {
      if (obj.length > 0) {
        return (
          <div>
            {obj.map((ele: { text: { value: string } }, idx: number) => {
              return <p key={idx}>{ele["text"]["value"]}</p>;
            })}
          </div>
        );
      } else {
        return <span>No data</span>;
      }
    }
  };
  return (
    <>
      <div className={styles.accordion}>
        <div
          className={
            props.status === AgentStatus.COMPLETED
              ? styles.header + (showContent ? " " + styles.expanded : "")
              : styles.header + " " + styles.disabled
          }
          onClick={() => setShowContent(!showContent)}
        >
          {getStatusIcon(props.status)}
          <span className={styles.headerText}>{props.title}</span>
          <RightOutlined
            className={
              showContent ? styles.icon + " " + styles.rotate : styles.icon
            }
          />
        </div>
      </div>
      <div
        className={
          showContent ? styles.content + " " + styles.show : styles.content
        }
      >
        {showContent && getContent(props.type as AgentType, props.content)}
      </div>
    </>
  );
}
