import axios from "axios";
import { RefObject, useEffect, useRef, useState } from "react";

import { ConfigProvider, Divider, Spin, Select, Modal, Skeleton } from "antd";
import { InteractSidebar } from "./components/InteractSidebar";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IConnectionProps } from "../database/Database";
import { baseUrl } from "../database/NewConnection";
import { useKeycloak } from "@react-keycloak/web";
import { IUserFeedbackProps } from "./components/Feedback";
import ChatV2 from "./components/Chatv2";
import rightOutline from "../../assets/icons/rightOutline.svg"
import settingIcon from "../../assets/icons/setting.svg"
import DBShortView from "./components/DBShortView";

export interface IQuestionAnswerPropsV2 {
    id?: number;
    questionId?: string;
    user?: string;
    question?: string;
    type?: string;
    answer?: any;
    workflow_response?: string;
    graphs?: any;
    assumptions?: string;
    query?: string;
    sqlQueryExplanation?: string;
    data?: any;
    isLoading?: boolean;
    selectedTab?: string;
    clarificationData?: any;
    finalConfirmationData?: any;
    feedback?: IUserFeedbackProps;
    chartSelection?: string;
    graphFetching?: boolean;
    summaryFetching?: boolean;
    timestamp?: Date;
}

const AdvanceInteract = () => {
    const { chatId, connectionConfigId } = useParams();
    console.log(connectionConfigId)
    const navigate = useNavigate();
    const controllerRef = useRef(new AbortController());
    const [showChat, setShowChat] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [result, setResult] = useState({});
    const [chatList, setChatList] = useState<any>([]);
    const [questionAnswerListV2, setQuestionAnswerListV2] = useState<
        Array<IQuestionAnswerPropsV2>
    >([]);
    const [isChatListLoading, setIsChatListLoading] = useState(true);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [isNewChat, setIsNewChat] = useState(false);
    const [sampleQuestionList, setSampleQuestionList] = useState([]);

    const updateTab = (value: Array<IQuestionAnswerPropsV2>) => {
        setQuestionAnswerListV2(value);
    };
    const [isConnectionListLoading, setIsConnectionListLoading] = useState(true);
    const [connectionList, setConnectionList] = useState<Array<IConnectionProps>>(
        []
    );
    const [next, setNext] = useState(null);
    const [totalChatListLength, setTotalChatListLength] = useState(0);
    const [FollowUpQuestions, setFollowUpQuestions] = useState<Array<string>>([]);
    const [latestQueryAgentId, setLatestQueryAgentId] = useState<string>("");
    const [isFollowUpQsLoading, setIsFollowUpQsLoading] = useState<boolean>(false);
    const [codeInterpreter, setCodeInterpreter] = useState<boolean>(true);
    useEffect(() => {
        console.log(codeInterpreter)
    }, [codeInterpreter])


    const { keycloak } = useKeycloak();

    useEffect(() => {
        axios
            .get(`${baseUrl}/connection/all/`)
            .then(function (response) {
                setConnectionList(response.data);
                if (response.data.length > 0 && !chatId && !connectionConfigId) {
                    navigate("/advance_qna/" + response.data[0].id);
                }
                setIsConnectionListLoading(false);
            })
            .catch(function (error) {
                console.log(error.response.data.error);
                setIsConnectionListLoading(false);
            });
    }, []);

    useEffect(() => {
        if (connectionConfigId) {
            setIsChatListLoading(true);
            setQuestionAnswerListV2([]);
            axios
                .get(`${baseUrl}/advance_qna/${connectionConfigId}/all/?items_per_page=15`)
                .then(function (response) {
                    setTotalChatListLength(response.data.count);
                    setNext(response.data.next);
                    setChatList(response.data.results);
                    setSampleQuestionList(response.data.sample_question_list);
                    setIsChatListLoading(false);
                });
        }
    }, [connectionConfigId]);



    useEffect(() => {
        setFollowUpQuestions([]);
        console.log(chatId);
        if (chatId && !isNewChat) {
            setIsChatLoading(true);
            axios
                .get(`${baseUrl}/advance_qna/${connectionConfigId}/chat-history/${chatId}/`)
                .then(function (response) {
                    setIsChatLoading(false);
                    var responseData: Array<any> = response.data;
                    setQuestionAnswerListV2(
                        responseData.map((row, i) => {
                            setFollowUpQuestions([]);
                            var questionAnswerV2: IQuestionAnswerPropsV2 = {};
                            questionAnswerV2.questionId = row.id;
                            questionAnswerV2.user = row.user;
                            questionAnswerV2.feedback = row.feedback;
                            questionAnswerV2.question = row.question;
                            questionAnswerV2.type = row.type;
                            questionAnswerV2.timestamp = new Date(row.timestamp);
                            if (row.type === "completion") {
                                console.log(row.response);
                                questionAnswerV2.answer = row.response;
                            } else if (row.type === "clarification") {
                                questionAnswerV2.clarificationData = JSON.parse(row.response);
                                if (i !== responseData.length - 1) {
                                    console.log(responseData.length - 1, i);
                                    questionAnswerV2.clarificationData.options = [];
                                }
                            } else if (row.type === "final_confirmation") {
                                questionAnswerV2.finalConfirmationData = JSON.parse(
                                    row.response
                                );
                                if (i !== responseData.length - 1) {
                                    questionAnswerV2.finalConfirmationData.confirmed = true;
                                }
                            } else if (row.type === "code_interpreter") {
                                questionAnswerV2.answer = row.response
                            }
                            questionAnswerV2.isLoading = false;
                            questionAnswerV2.summaryFetching = false;
                            questionAnswerV2.graphFetching = false;
                            questionAnswerV2.selectedTab = "2";
                            return questionAnswerV2;
                        })
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        setIsNewChat(false);
    }, [chatId]);

    const callExampleQuestion = (exampleQuestion: string) => {
        getData(exampleQuestion);
    };



    // const getFollowUpQuestions = (agentId: string) => {
    //     setIsFollowUpQsLoading(true);
    //     if (chatId && connectionConfigId) {
    //         axios
    //             .post(
    //                 `${baseUrl}/chat/v3/${connectionConfigId}/${chatId}/get-followup-questions/`,
    //                 {
    //                     id: agentId,
    //                 }
    //             )
    //             .then((response: any) => {
    //                 setFollowUpQuestions([...FollowUpQuestions, ...response.data]);
    //                 setIsFollowUpQsLoading(false);
    //             })
    //             .catch((error) => {
    //                 setIsFollowUpQsLoading(false);
    //                 console.log(error);
    //             });
    //     }
    // };

    const getData = (question: string) => {
        setFollowUpQuestions([]);
        setIsLoading(true);
        var idV2 = questionAnswerListV2.length + 1;
        var tempQuestionAnswerListV2 = [...questionAnswerListV2];
        if (idV2 > 1) {
            if (tempQuestionAnswerListV2[idV2 - 2].type === "clarification") {
                tempQuestionAnswerListV2[idV2 - 2].clarificationData.options = [];
            } else if (
                tempQuestionAnswerListV2[idV2 - 2].type === "final_confirmation"
            ) {
                tempQuestionAnswerListV2[idV2 - 2].finalConfirmationData.confirmed =
                    true;
            }
        }
        tempQuestionAnswerListV2.push({
            id: idV2,
            user: keycloak.idTokenParsed?.preferred_username,
            question: question,
            data: [],
            isLoading: true,
            selectedTab: "2",
        });
        questionAnswerListV2.push({
            id: idV2,
            user: keycloak.idTokenParsed?.preferred_username,
            question: question,
            data: [],
            isLoading: true,
            selectedTab: "2",
        });
        setQuestionAnswerListV2(tempQuestionAnswerListV2);
        getQuery(idV2, question);
    };

    const getQuery = (id: number, ques: string) => {
        setShowChat(true);
        const controller = new AbortController();
        controllerRef.current.abort(); // Cancel previous request
        controllerRef.current = controller; // Update the current AbortController
        axios
            .post(
                `${baseUrl}/advance_qna/${connectionConfigId}/question/`,
                {
                    input: ques,
                    chatId: chatId,
                    codeInterpreter: codeInterpreter
                },
                {
                    signal: controller.signal,
                    timeout: 300000,
                }
            )
            .then(function async(response) {
                var tempQuestionAnswerListV2 = [...questionAnswerListV2];
                var questionAnswerV2 = tempQuestionAnswerListV2.filter(
                    (row) => row.id === id
                )[0];
                questionAnswerV2.questionId = response.data.id;
                questionAnswerV2.user = response.data.user;
                questionAnswerV2.type = response.data.type;
                questionAnswerV2.timestamp = new Date(response.data.timestamp)
                if (response.data.type === "completion") {
                    questionAnswerV2.answer = response.data.response;
                } else if (response.data.type === "clarification") {
                    questionAnswerV2.clarificationData = JSON.parse(
                        response.data.response
                    );
                } else if (response.data.type === "final_confirmation") {
                    questionAnswerV2.finalConfirmationData = JSON.parse(
                        response.data.response
                    );
                    questionAnswerV2.finalConfirmationData.confirmed = false;
                } else if (response.data.type === "code_interpreter") {

                    questionAnswerV2.answer = response.data.response
                }
                questionAnswerV2.isLoading = false;
                // console.log(tempQuestionAnswerList, tempQuestionAnswerListV2);
                // setQuestionAnswerList(tempQuestionAnswerList);
                setQuestionAnswerListV2(tempQuestionAnswerListV2);
                if (response.data.chat) {
                    setIsNewChat(true);
                    chatList.unshift(response.data.chat);
                    navigate(`/advance_qna/${connectionConfigId}/${response.data.chat.id}`);
                }
                setIsLoading(false);
            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    console.log("Request canceled", error.message);
                    setIsLoading(false);
                } else {
                    console.log(error);
                    var tempQuestionAnswerListV2 = [...questionAnswerListV2];
                    var questionAnswerV2 = tempQuestionAnswerListV2.filter(
                        (row) => row.id === id
                    )[0];
                    questionAnswerV2.answer = "Error... Try again";
                    questionAnswerV2.isLoading = false;
                    setQuestionAnswerListV2(tempQuestionAnswerListV2);
                    setIsLoading(false);
                }
            });
    };
    // Interact Sidebar State Handler
    const [activeInteractSidebar, setActiveInteractSidebar] = useState(true);
    const toggleInteractSidebar = () => setActiveInteractSidebar((pre) => !pre)

    // Database Config Setting Handlers
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalInput, setModalInput] = useState("");

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // Database Config Setting Handlers



    return (
        <ConfigProvider>
            {

                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        fontFamily: "Poppins, sans-serif",
                    }}
                >
                    {/* Interact Sidebar - Start */}
                    <div
                        style={{
                            height: "100%",
                            width: "220px",
                            margin: "0",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            transition: "0.5s",
                            marginLeft: activeInteractSidebar ? "0" : "-220px",
                            minWidth: "220px"
                        }}
                    >
                        <InteractSidebar
                            connectionConfigId={connectionConfigId}
                            chatId={chatId}
                            setQuestionAnswerList={updateTab}
                            controllerRef={controllerRef}
                            chatList={chatList}
                            isChatListLoading={isChatListLoading}
                            connectionList={connectionList}
                            isConnectionListLoading={isConnectionListLoading}
                            setChatList={setChatList}
                            next={next}
                            setNext={setNext}
                            totalChatListLength={totalChatListLength}
                            setTotalChatListLength={setTotalChatListLength}
                            toggleInteractSidebar={toggleInteractSidebar}
                        />
                    </div>

                    <Divider
                        type="vertical"
                        style={{
                            height: "100%",
                            margin: "0px",
                            borderColor: "#EAECF0",
                            borderWidth: "1px"
                        }}
                    />
                    {!activeInteractSidebar && <div onClick={toggleInteractSidebar} style={{ height: "100%", display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <img src={rightOutline} alt="" />
                    </div>}

                    {/* Interact Sidebar - End */}

                    {/* Interact Main Screen  */}
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#fff",
                        }}
                    >

                        {/* Database Config Setting - Start */}
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 5px", height: "52px" }}>
                            <Select
                                className="selectdb"
                                value={isConnectionListLoading ? "" : connectionConfigId}
                                loading={isConnectionListLoading}
                                onChange={(e) => {
                                    navigate(`/advance_qna/${e}`);
                                }}
                                options={connectionList.map((row) => ({
                                    value: row.id,
                                    label: row.name,
                                }))}
                                style={{ minWidth: "150px" }}
                            />
                            <button className="rm-hover" style={{
                                marginLeft: "10px",
                                padding: "5px",
                                borderRadius: "6px",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center',
                                background: "white",
                                border: "1px solid #dcdde0",
                                cursor: "pointer"

                            }}
                                disabled={isLoading}
                                onClick={() => setIsModalOpen(pre => !pre)}>
                                <img src={settingIcon} alt="" />
                            </button>
                            <Modal
                                centered
                                width={900}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={null}
                                zIndex={6}
                                title="Database Details"
                            >
                                <DBShortView
                                    modalInput={modalInput}
                                    getData={getData}
                                    setIsModalOpen={setIsModalOpen}
                                    setModalInput={setModalInput}
                                    showCheckBox={true}
                                />
                            </Modal>

                        </div>
                        {/* Database Config Setting - End */}



                        {/* Chat - Start */}
                        <div
                            style={{
                                height: "calc(100vh - 52px)",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {connectionConfigId && (
                                <>
                                    {isChatLoading || (!chatId && isChatListLoading) ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                height: "100%",
                                                flexDirection: "column",
                                                padding: "12px",
                                                gap: 20,
                                                // border: "2px solid red"
                                            }}
                                        >
                                            <div style={{ marginLeft: "auto", width: "60%" }}>
                                                <Skeleton.Input active block />
                                            </div>
                                            <Skeleton active style={{ width: "60%" }} />
                                            <div style={{ marginLeft: "auto", width: "40%" }}>
                                                <Skeleton.Input active block />
                                            </div>
                                            <Skeleton active style={{ width: "50%" }} />
                                            <div style={{ marginLeft: "auto", width: "50%" }}>
                                                <Skeleton.Input active block />
                                            </div>
                                            <Skeleton active style={{ width: "70%" }} />
                                        </div>
                                    ) : (
                                        <ChatV2
                                            key={chatId}
                                            questionAnswerListV2={questionAnswerListV2}
                                            setQuestionAnswerListV2={setQuestionAnswerListV2}
                                            getData={getData}
                                            callExampleQuestion={callExampleQuestion}
                                            sampleQuestionList={sampleQuestionList}
                                            isLoading={isLoading}
                                            FollowUpQuestions={FollowUpQuestions}
                                            codeInterpreter={codeInterpreter}
                                            setCodeInterpreter={setCodeInterpreter}
                                        />
                                    )}
                                </>
                            )}
                        </div>



                    </div>
                </div>
                // )
            }
        </ConfigProvider >
    );
};
export default AdvanceInteract;
