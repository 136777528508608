
import { ConfigProvider, Input } from "antd";
import { useEffect, useState } from "react";
import rightArrowIcon from "../../../assets/icons/rightArrow.svg"
import { Button, Dropdown, Switch } from "antd";
import { DownOutlined } from "@ant-design/icons";

export const PromptInput = (props) => {
  const { getData, isLoading, codeInterpreter, setCodeInterpreter } = props;
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    setInputVal("");
  }, [isLoading]);
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
      <Input
        disabled={isLoading}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        style={{
          padding: "8px 12px",
          borderRadius: "12px",
          fontSize: "14px",
          fontFamily: "Poppins, sans-serif",
          border: "2px solid #E8ECEF",
          boxShadow: 'none',
          "&::placeholder": {
            color: "red"
          },
          // width: "100%"
        }}

        placeholder="Send a message..."
        onPressEnter={() => {
          if (inputVal) {
            getData(inputVal);
          }
        }}
      />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#5e5adb"
          }
        }}
      >
        <Dropdown.Button
          type="primary"
          size="large"
          menu={{
            items: [{
              "label": (<div onClick={(e) => e.stopPropagation()}>
                <span>Code Interpreter &nbsp; </span>
                <Switch
                  defaultChecked
                  checked={codeInterpreter}
                  onChange={() => {
                    setCodeInterpreter((pre) => !pre)
                  }}
                />
              </div>),
              "key": 1
            }]
          }} placement="top"
          icon={<DownOutlined />}
          onClick={() => {
            getData(inputVal);
          }}
          onPressEnter={() => {
            if (inputVal) {
              getData(inputVal);
            }
          }}
          style={{ width: "fit-content" }}

        >
          Send
        </Dropdown.Button>
      </ConfigProvider>
    </div>
  );
};
