import { Tag } from "antd";
import { InsightFeedProps, CategoryType } from "../Insight";

export default function InsightTags(data:InsightFeedProps) {
  return (
    <>
      <Tag color="yellow" style={{ textTransform: "capitalize" }}>
        {data.metric.metric_label}
      </Tag>
      {
        <Tag color="blue" style={{ textTransform: "capitalize" }}>
          {data.segment_value_list.length > 0
            ? data.segment_value_list
                .map((p) => {
                  return p.value;
                })
                .join(", ")
            : "Overall"}
        </Tag>
      }
      <Tag color="purple" style={{ textTransform: "capitalize" }}>
        {data.frequency.frequency_label}
      </Tag>
      <Tag
        color={data.type === CategoryType.Threat ? "red" : "green"}
        style={{ textTransform: "capitalize" }}
      >
        {data.type}
      </Tag>
    </>
  );
}
