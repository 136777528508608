export function getFormattedDate(date: string): string {
  return new Date(date).toLocaleDateString("en-IN", {
    day: "numeric",
    month: "short",
    year: "2-digit",
  });
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatNumberToString(num: string): string {
  const numFloat = parseFloat(num)
  if (numFloat > 999999) {
    return `${(numFloat / 1000000).toFixed(2)}M`
  }
  if (numFloat > 9999) {
    return `${(numFloat / 10000).toFixed(2)}K`
  }
  return `${numFloat.toFixed(2)}`
}

export function formatTimestamp(date?: Date): string {
  if (!date) {
    return 'Invalid date'; // or any other default value or throw an error
  }
  const now: Date = new Date();
  const todayStart: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterdayStart: Date = new Date(todayStart);
  yesterdayStart.setDate(todayStart.getDate() - 1);

  const timeDiff: number = now.getTime() - date.getTime();
  const minutesAgo: number = Math.floor(timeDiff / (1000 * 60));

  if (minutesAgo < 5) {
    return 'Just now';
  } else if (date >= todayStart) {
    return 'Today ' + new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
  } else if (date >= yesterdayStart) {
    return 'Yesterday ' + new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
  } else {
    const formattedSpecificDate: string = date.toLocaleString('en-GB', {
      day: "numeric",
      month: "short",
      year: "2-digit",
    }) + ' ' + new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
    return formattedSpecificDate;
  }
}

export function throttle(callback: Function, delay: number) {
  let lastCall = 0;

  return function (...args: any) {
    const now = new Date().getTime();

    if (now - lastCall >= delay) {
      callback(...args);
      lastCall = now;
    }
  };
}
