import { Line } from "@ant-design/plots";
import { capitalizeFirstLetter, formatNumberToString } from "../../utils/Helpers";

interface GraphProps {
  xField: string;
  yField: string;
  data: [];
}
export default function Graph(props: GraphProps) {
  const { data, xField, yField } = props;
  const dataForGraph = data.length > 10 ? data.slice(0,10) : data 
  return (
    <Line
      data={dataForGraph}
      xField={xField}
      yField={yField}
      style={{ height: "100%" }}
      smooth
      xAxis={{
        title: {
          text: capitalizeFirstLetter(xField),
        },
      }}
      yAxis={{
        label: {
          formatter: formatNumberToString,
        },
        title: {
          text: capitalizeFirstLetter(yField),
        },
      }}
      annotations={[
        {
          type: "dataMarker",
          line: {
            length: 0,
          },
          point: {
            style: {
              fill: "#d46b08",
              stroke: "#d46b08",
            },
          },
          position: [
            dataForGraph[dataForGraph.length - 2][xField],
            dataForGraph[dataForGraph.length - 2][yField],
          ],
        },
        {
          type: "regionFilter",
          start: ["min", "min"],
          end: ["max", "max"],
          color: "rgb(56, 158, 13)",
          style: {
            fill: "rgb(56, 158, 13)",
          },
        },
        {
          type: "regionFilter",
          start: [
            dataForGraph[dataForGraph.length - 2][xField],
            "min",
          ],
          end: [
            dataForGraph[dataForGraph.length - 1][xField],
            "max",
          ],
          color: "rgb(212, 107, 8)",
          style: {
            fill: "rgb(212, 107, 8)",
          },
        },
      ]}
    />
  );
}
