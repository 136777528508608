import { Menu, Table, Typography } from "antd";
import { IConnectionDbColumnConfig } from "./DatabaseDetail";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import { baseUrl } from "../NewConnection";
import { useParams } from "react-router-dom";
import style from '../Database.module.css'


interface IDataViewProps {
  connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  };
}

interface IViewDataConfig {
  data: Array<string>;
  no_of_row: number;
}
export const DataView = (props: IDataViewProps) => {
  const { connectionDbConfig } = props;
  const [selectedTableName, setSelectedTableName] = useState<string>(
    connectionDbConfig ? Object.keys(connectionDbConfig)[0] : ""
  );
  const [viewDataConfig, setViewDataConfig] = useState<IViewDataConfig>();
  const { connectionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Add this state
  const itemsPerPage = 10;

  useEffect(() => {
    if (selectedTableName) {
      fetchData(1);
    }
  }, [selectedTableName]);

  const fetchData = (page: number) => {
    setLoading(true);
    setCurrentPage(page);
    axios
      .post(`${baseUrl}/connection/${connectionId}/data/`, {
        tableName: selectedTableName,
        page: page,
      })
      .then(function (response) {
        setViewDataConfig(response.data);
        console.log(response);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setLoading(false);
      });
  };

  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node!.getBoundingClientRect();
    setTableHeight(window.innerHeight - top - 120);
  }, [ref]);



  return (
    <div style={{ display: "flex", height: "calc(100% - 72px)", }}>
      <div style={{ height: "100%", overflowX: 'auto', }}>
        <Menu
          onClick={(e) => setSelectedTableName(e.key)}
          style={{
            minWidth: 156,
            borderRight: 0
          }}
          selectedKeys={[selectedTableName]}
          mode="inline"
          items={Object.keys(connectionDbConfig)?.map((tableName) => ({
            key: tableName,
            label: tableName,
          }))}
        />

      </div>
      <div style={{ flex: 6, height: "100%", display: "flex", overflowY: "hidden", marginRight: "16px", }}>
        <Table
          style={{ height: "100%", }}

          columns={connectionDbConfig[selectedTableName].map((row) => ({
            title: row.name,
            dataIndex: row.name,
            ellipsis: true,
            width: row.name.length * 12
          }))}
          dataSource={viewDataConfig?.data.map((row) => {
            const obj: any = {};
            connectionDbConfig[selectedTableName].forEach(
              (key, index) => {
                obj[key.name] = row[index];
              }
            );
            return obj;
          })}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: itemsPerPage,
            total: viewDataConfig?.no_of_row,
            onChange: (page) => fetchData(page),
          }}
          ref={ref}
          scroll={{ x: 'max-content', y: tableHeight }}
        />
      </div>
    </div>
  );
};
