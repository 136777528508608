import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar, Column, Line, Pie } from '@ant-design/plots';
import { Slider } from './Slider';
import { BarChartOutlined, CheckOutlined, EditOutlined, LineChartOutlined, PieChartOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { Select, Space } from 'antd';
import { baseUrl } from "../../database/NewConnection";
import axios from "axios";

export const formatString = (word) => {
  try {
    return word.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  } catch (error) {
    return word
  }
}

export const DisplayChart = (props) => {
  const { graphs, data } = props;
  const [selectedChart, setSelectedChart] = useState(0)

  const updateData = (chartFieldDescription) => {
    try {
      const groupedData = {};
      data.forEach(item => {
        const key = `${item[chartFieldDescription.xField]}-${item[chartFieldDescription.seriesField]}`;

        if (!groupedData[key]) {
          groupedData[key] = {
            [chartFieldDescription.xField]: item[chartFieldDescription.xField],
            [chartFieldDescription.seriesField]: item[chartFieldDescription.seriesField],
            [chartFieldDescription.yField]: parseInt(item[chartFieldDescription.yField]),
          };
        } else {
          groupedData[key][chartFieldDescription.yField] += parseFloat(item[chartFieldDescription.yField]);
        }
      });

      const resultArray = Object.values(groupedData);

      if (chartFieldDescription.xField === "month" || chartFieldDescription.xField === "month_year") {
        console.log(data)
        return resultArray
          .map(row => {
            return {
              ...row,
              [chartFieldDescription.xField]: row[chartFieldDescription.xField] && row[chartFieldDescription.xField].toString(),
              [chartFieldDescription.yField]: parseInt(row[chartFieldDescription.yField])
            }
          }).sort(function (a, b) {
            var date1 = a[chartFieldDescription.xField]
            var date2 = b[chartFieldDescription.xField]
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const [month1, year1] = date1.split(/\s+/);
            const [month2, year2] = date2.split(/\s+/);

            const index1 = monthNames.indexOf(month1);
            const index2 = monthNames.indexOf(month2);
            if (year1 !== year2) {
              return parseInt(year1) - parseInt(year2);
            }

            return index1 - index2;
          })
      }
      var k = resultArray
        .map(row => {
          return {
            ...row,
            [chartFieldDescription.xField]: row[chartFieldDescription.xField] && row[chartFieldDescription.xField].toString(),
            [chartFieldDescription.yField]: parseInt(row[chartFieldDescription.yField])
          }
        })
        .sort((a, b) => {
          try {
            if (b[chartFieldDescription.xField] !== a[chartFieldDescription.xField]) {
              return (a[chartFieldDescription.xField] ? a[chartFieldDescription.xField] : "").localeCompare(b[chartFieldDescription.xField] ? b[chartFieldDescription.xField] : "");
            }
            return (a[chartFieldDescription.seriesField] ? a[chartFieldDescription.seriesField] : "").localeCompare(b[chartFieldDescription.seriesField] ? b[chartFieldDescription.seriesField] : "");
          } catch (error) {
            return ""
          }
        })
      return k;

    } catch (error) {
      return []
    }
  }

  const getChart = (graph) => {
    try {

      const chartSelection = graph.graph_name
      const chartFieldDescription = graph.config
      if (chartSelection === "MultiLine") {
        const config = {
          data: updateData(chartFieldDescription),
          xField: chartFieldDescription.xField ? chartFieldDescription.xField : NaN,
          yField: chartFieldDescription.yField ? chartFieldDescription.yField : NaN,
          seriesField: chartFieldDescription.seriesField ? chartFieldDescription.seriesField : NaN,
          yAxis: {
            title: {
              text: formatString(chartFieldDescription.yField) + " →",
              style: {
                fontSize: 16,
              }
            },
            // type: 'time',
            tickCount: 5,
          },
          xAxis: {
            title: {
              text: formatString(chartFieldDescription.xField) + " →",
              style: {
                fontSize: 16,
              }
            },
            // type: 'time',
            tickCount: 5,
          },
        };
        console.log(config)
        return (<Line {...config} />)
      }
      if (chartSelection === "GroupedBar" && chartFieldDescription.xField && chartFieldDescription.yField) {
        const config = {
          data: updateData(chartFieldDescription),
          isGroup: true,
          xField: (chartFieldDescription.xField && chartFieldDescription.xField !== "") ? chartFieldDescription.xField : NaN,
          yField: (chartFieldDescription.yField && chartFieldDescription.yField !== "") ? chartFieldDescription.yField : NaN,
          seriesField: (chartFieldDescription.seriesField && chartFieldDescription.seriesField !== "") ? chartFieldDescription.seriesField : NaN,
          yAxis: {
            title: {
              text: formatString(chartFieldDescription.yField) + " →",
              style: {
                fontSize: 16,
              }
            },
            // type: 'time',
            tickCount: 5,
          },
          xAxis: {
            title: {
              text: formatString(chartFieldDescription.xField) + " →",
              style: {
                fontSize: 16,
              }
            },
            // type: 'time',
            tickCount: 5,
          },
        };
        return <Column {...config} />;
      }
      if (chartSelection === "Pie") {
        const config = {
          appendPadding: 10,
          data: data.map(row => {
            return {
              ...row,
              [chartFieldDescription.colorField]: row[chartFieldDescription.colorField] && row[chartFieldDescription.colorField].toString(),
              [chartFieldDescription.angleField]: parseInt(row[chartFieldDescription.angleField])
            }
          }),
          angleField: chartFieldDescription.angleField ? chartFieldDescription.angleField : NaN,
          colorField: chartFieldDescription.colorField ? chartFieldDescription.colorField : NaN,
          radius: 0.9,
          label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
              fontSize: 14,
              textAlign: 'center',
            },
          },
          interactions: [
            {
              type: 'element-active',
            },
          ],
        };
        return <Pie {...config} />;
      }
    } catch (error) {
      return <></>
    }
  }

  const graphFieldMap = {
    "MultiLine": ["xField", "yField", "seriesField"],
    "GroupedBar": ["xField", "yField", "seriesField"],
    "Pie": ["angleField", "colorField"]
  }
  const graphIconValueMap = {
    "Line": <LineChartOutlined />,
    "MultiLine": <LineChartOutlined />,
    "Bar": <BarChartOutlined />,
    "GroupedBar": <BarChartOutlined />,
    "Pie": <PieChartOutlined />
  }

  return (
    <>
      {(graphs && graphs.length > 0) &&
        (graphs.filter(row => {
          return !(row.config.xField && !(Object.keys(data[0])).includes(row.config.xField)) &&
            !(row.config.yField && !(Object.keys(data[0])).includes(row.config.yField)) &&
            !(row.config.seriesField && !(Object.keys(data[0])).includes(row.config.seriesField)) &&
            !(row.config.angleField && !(Object.keys(data[0])).includes(row.config.angleField)) &&
            !(row.config.colorField && !(Object.keys(data[0])).includes(row.config.colorField))
        }).length > 0 && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', gap: 10 }}>
                  {
                    graphs.length > 1 && (
                      <Slider buttons={
                        graphs.filter(row => {
                          return !(row.config.xField && !(Object.keys(data[0])).includes(row.config.xField)) &&
                            !(row.config.yField && !(Object.keys(data[0])).includes(row.config.yField)) &&
                            !(row.config.seriesField && !(Object.keys(data[0])).includes(row.config.seriesField)) &&
                            !(row.config.angleField && !(Object.keys(data[0])).includes(row.config.angleField)) &&
                            !(row.config.colorField && !(Object.keys(data[0])).includes(row.config.colorField))
                        }).map((graph, index) => {
                          return {
                            name: "", value: index, icon: graphIconValueMap[graph.graph_name]
                          }
                        })
                      }
                        defaultValue={selectedChart} onValueChange={(v) => { setSelectedChart(v) }}
                      />
                    )
                  }
                </div>
              </div>
              <div style={{ display: 'flex', width: "auto", overflowX: "", gap: 10, marginTop: "20px" }}>
                <div style={{ width: "100%" }}>
                  {getChart(graphs[selectedChart])}
                </div>
              </div>
            </>
          ))
      }
    </>
  );
}