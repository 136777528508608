import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Button, Table, Divider, ConfigProvider } from "antd";
import { IQuestionAnswerPropsV2 } from "../AdvanceInteract";
import { BulbOutlined } from "@ant-design/icons";
import { ExportWidget } from "./ExportWidget";
import { Feedback } from "./Feedback";
import CustomLoader from "../../utils/CustomLoader";
import crux_small_icon from "../../../assets/crux_small.svg";
import { PromptInput } from "./PromptInput";
import style from "../Interact.module.css";
import CodeBlock from "../../utils/CodeBlock";
import chatIcon from "../../../assets/icons/chat.svg";
import brainStromIcon from "../../../assets/icons/brainstrom.svg";
import axios from "axios";
import { configToUse } from "../../../Keycloak";
import useInterval from "./useInterval";
import Accordion from "./Accordion";
import styles from './ChatV2.module.css'
import { formatTimestamp } from "../../utils/Helpers";

export enum AgentStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}
type AgentResponse = {
  status: AgentStatus;
  input: string;
  type: string;
  response?: any;
};

export enum AgentType {
  CODE = "code_interpreter",
  QUERY = "query",
}

type StatusResponseType = {
  [key: string]: AgentResponse;
};

const baseUrl = configToUse.REACT_APP_API_BASE_URL;
const POLLING_INTERVAL = 4000
const fetchAgentStatus = async (agentList: string[]) => {
  try {
    const response = await axios.get(`${baseUrl}/advance_qna/agent-status/?agents=${agentList.join('&agents=')}`, {
      // headers: {
      //   "Cache-Control": "no-cache",
      //   Pragma: "no-cache",
      //   Expires: "0",
      // },
    });
    return response.data;
  } catch {
    console.log("error");
  }
};

const QueryResult = (props: {
  row: IQuestionAnswerPropsV2;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { row, setLoading } = props;
  const [pollDelay, setPollDelay] = useState<number | null>(null);
  const [agents, setAgents] = useState<{ [key: string]: AgentResponse }>({});
  const pendingAgents = useRef<string[]>([]);

  useEffect(() => {
    const allAgents = row.answer?.agents
    const pending = Object.keys(allAgents).filter((id) => allAgents[id]["status"] === AgentStatus.IN_PROGRESS)
    pendingAgents.current = pending

    setAgents(allAgents);
  }, []);

  useEffect(() => {
    if (pendingAgents.current && pendingAgents.current.length > 0) {
      setPollDelay(POLLING_INTERVAL);
      setLoading(true)
    } else {
      setPollDelay(null);
      setLoading(false)
    }
  }, [pendingAgents.current?.length]);

  const handleAgentUpdate = (res: StatusResponseType) => {
    setAgents((prev) => {
      return { ...prev, ...res };
    });
    const currPending: string[] = [];
    if (res)
      Object.entries(res).forEach(([id, data]) => {
        if (data.status === AgentStatus.IN_PROGRESS) {
          currPending.push(id);
        }
      });
    pendingAgents.current = currPending;
  };

  useInterval(async () => {
    const newStatus = await fetchAgentStatus(pendingAgents.current);
    handleAgentUpdate(newStatus);
  }, pollDelay);

  return (
    <div className={styles.agentList}>
      {Object.entries(agents).map(([id, data]) => {
        return (
          <Accordion
            key={id}
            status={data.status}
            title={data.input}
            content={data.response}
            type={data.type}
          />
        );
      })}
    </div>
  );
};

const ChatV2 = (props: {
  questionAnswerListV2: IQuestionAnswerPropsV2[];
  setQuestionAnswerListV2: React.Dispatch<
    React.SetStateAction<IQuestionAnswerPropsV2[]>
  >;
  getData: any;
  callExampleQuestion: any;
  sampleQuestionList: any;
  isLoading: any;
  FollowUpQuestions: any;
  codeInterpreter: boolean;
  setCodeInterpreter: any;
}) => {
  const {
    questionAnswerListV2,
    setQuestionAnswerListV2,
    getData,
    callExampleQuestion,
    sampleQuestionList,
    isLoading,
    FollowUpQuestions,
    codeInterpreter,
    setCodeInterpreter
  } = props;

  console.log(questionAnswerListV2);

  const [isAssistantRunning, setIsAssistantRunning] = useState<boolean>(false);

  // Scrolling chat container to bottom
  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chatContainerRef.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  }, [questionAnswerListV2.length]);

  return (
    <div
      style={{
        height: "100%",
        padding: "14px 16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Examples Questions - Start*/}
      {questionAnswerListV2.length === 0 && (
        <div
          style={{
            alignItems: "flex-start",
            height: "100%",
            justifyContent: "center",
            display: "flex",
            fontSize: "20px",
            gap: 64,
          }}
        >
          {sampleQuestionList && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <div style={{ alignSelf: "self-start" }}>
                <img src={brainStromIcon} alt="" />
                <div style={{ margin: "2px 0 10px 0", fontWeight: 600 }}>
                  Brainstrom
                </div>
              </div>
              {Array.isArray(sampleQuestionList) ? (sampleQuestionList.map((row: any) => (
                <div
                  onClick={() => {
                    callExampleQuestion(row);
                  }}
                  className={style.sampleQuestion}
                  style={{
                    width: "420px",
                    height: "auto",
                    padding: "12px",
                    fontSize: "15px",
                    borderRadius: "10px",
                    whiteSpace: "normal",
                  }}
                >
                  {`"${row}" `}
                </div>
              ))) : <>
                {
                  sampleQuestionList["brainstorm"].map((row: any) => (
                    <div
                      onClick={() => {
                        callExampleQuestion(row);
                      }}
                      className={style.sampleQuestion}
                      style={{
                        width: "420px",
                        height: "auto",
                        padding: "12px",
                        fontSize: "15px",
                        borderRadius: "10px",
                        whiteSpace: "normal",
                      }}
                    >
                      {`"${row}" `}
                    </div>
                  ))
                }
              </>
              }
            </div>
          )}
          {sampleQuestionList && typeof sampleQuestionList === "object" && !Array.isArray(sampleQuestionList) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <div style={{ alignSelf: "self-start" }}>
                <img src={chatIcon} alt="" />
                <div style={{ margin: "2px 0 10px 0", fontWeight: 600 }}>
                  Analyse
                </div>
              </div>
              {sampleQuestionList["analyse"].map((row: any) => (
                <div
                  onClick={() => {
                    callExampleQuestion(row);
                  }}
                  className={style.sampleQuestion}
                  style={{
                    width: "420px",
                    height: "auto",
                    padding: "12px",
                    fontSize: "15px",
                    borderRadius: "10px",
                    whiteSpace: "normal",
                  }}
                >
                  {`"${row}" `}
                </div>
              ))}
            </div>
          )}

        </div>
      )
      }
      {/* Examples Questions - End*/}

      {/* Chats - Start */}
      <div
        className="hidden-scrollbar"
        ref={chatContainerRef}
        style={{ overflowY: "auto", padding: "10px" }}
      >
        {/* Question-Answer Mapping */}
        {questionAnswerListV2.map((row, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "column" }}>
            {/* Chat You Sent */}
            <div
              style={{
                display: "flex",
                alignSelf: "end",
                maxWidth: "40%",
                flexDirection: "column",
                gap: "5px",
                fontSize: "14px",
              }}
            >
              <div>You</div>
              <div
                style={{
                  padding: "10px 14px 10px 14px",
                  color: "white",
                  background: "#5E5ADB",
                  borderEndEndRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                {row.question}
              </div>
            </div>
            {/* Resopnse You Got */}
            <div
              style={{
                display: "flex",
                gap: 10,
                padding: "20px",
                borderRadius: "16px",
                backgroundColor: "#fff",
                // border: "2px solid red",
                maxWidth: "55%",
              }}
            >
              {/* avatar */}
              <div style={{}}>
                <img
                  src={crux_small_icon}
                  alt="avatar"
                  style={{ height: "40px" }}
                />
              </div>
              {row.isLoading ? (
                <CustomLoader
                  // isLoading={row.isLoading}
                  text={
                    questionAnswerListV2[index - 1]?.type === "completion" ||
                      index === 0 ||
                      questionAnswerListV2[index - 1].type === "query"
                      ? "Understanding your question"
                      : questionAnswerListV2[index - 1].type ===
                        "final_confirmation"
                        ? "Fetching the data"
                        : "Analysing the information you provided"
                  }
                />
              ) : (
                <div>
                  <span style={{
                    color: "var(--colors-text-text-tertiary-600, #475467)",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "18px"
                  }}>{formatTimestamp(row?.timestamp as Date)}</span>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    {/* if type completion */}
                    {row.type === "completion" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.answer +
                              ""
                                .replace(/\n/g, "<br />")
                                .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                          }}
                          style={{
                            padding: "12px 14px 12px 14px",
                            border: "1px solid #EAECF0",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            background: "#F9FAFB",
                          }}
                        ></div>
                      </>
                    )}
                    {/* if type clarification */}
                    {row.clarificationData && row.type === "clarification" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              row.clarificationData?.question +
                              ""
                                .replace(/\n/g, "<br />")
                                .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                          }}
                          style={{
                            padding: "12px 14px 12px 14px",
                            border: "1px solid #EAECF0",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            background: "#F9FAFB",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            gap: 10,
                          }}
                        >
                          {row.clarificationData.options.map(
                            (option: string, i: number) => (
                              <button
                                key={i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const updatedList = [...questionAnswerListV2];
                                  updatedList[index].clarificationData.options =
                                    [];
                                  setQuestionAnswerListV2(updatedList);
                                  getData(option);
                                }}
                                className={style.optionBtn}
                                style={{}}
                              >
                                {option}
                              </button> // Wrapped each option in a <span> and added a key
                            )
                          )}
                        </div>
                      </>
                    )}
                    {/* if type final confirmation */}
                    {row.finalConfirmationData &&
                      row.type === "final_confirmation" && (
                        <>
                          <div
                            style={{
                              padding: "12px 14px 16px 14px",
                              border: "1px solid #EAECF0",
                              borderTopRightRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              borderBottomRightRadius: "10px",
                              background: "#F9FAFB",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  "Just to confirm, here is a summary of our conversation:"
                                    .replace(/\n/g, "<br />")
                                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                              style={{ marginBottom: "10px" }}
                            ></div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  "<b>" +
                                  row.finalConfirmationData?.redefined_input +
                                  "</b>"
                                    .replace(/\n/g, "<br />")
                                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                              style={{ marginBottom: "10px" }}
                            ></div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: "Does this looks good?"
                                  .replace(/\n/g, "<br />")
                                  .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                              }}
                            ></div>
                          </div>

                          {!row.finalConfirmationData?.confirmed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  justifyContent: "flex-end",
                                  // justifyContent: "space-around",
                                  gap: 10,
                                }}
                              >
                                <button
                                  className={style.optionBtn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const updatedList = [...questionAnswerListV2];
                                    updatedList[
                                      index
                                    ].finalConfirmationData.confirmed = true;
                                    setQuestionAnswerListV2(updatedList);
                                    getData("Yes");
                                  }}
                                >
                                  {"Yes, show me the answer"}
                                </button>
                                <button
                                  className={style.optionBtn}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const updatedList = [...questionAnswerListV2];
                                    updatedList[
                                      index
                                    ].finalConfirmationData.confirmed = true;
                                    setQuestionAnswerListV2(updatedList);
                                    getData("No, this isn't quite right");
                                  }}
                                >
                                  {"No, this isn't quite right"}
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    {row.type === "code_interpreter" && (
                      <QueryResult row={row} setLoading={setIsAssistantRunning} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        {/* folowup questions */}
        {FollowUpQuestions && FollowUpQuestions?.length !== 0 && (
          <div
            className="hidden-scrollbar"
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: 10,
              // justifyContent: "space-between",
              scrollbarWidth: "none",
              flexWrap: "nowrap",
              cursor: "default",
              // border: "2px solid red",
              alignItems: "center",

              marginBottom: "10px",
            }}
          >
            <span
              style={{
                color: "#6C727580",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Explore
            </span>
            {FollowUpQuestions.map((question: string, i: any) => {
              return (
                <div
                  key={i}
                  style={{
                    flex: "0 0 auto",
                    padding: "10px",
                    border: "1px solid rgba(94, 90, 219, 0.15)",
                    borderRadius: "10px",
                    color: "#5E5ADB",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    // setFollowUpQuestions([]);
                    getData(question);
                  }}
                >
                  ✨ {question}
                </div>
              );
            })}
          </div>
        )}

        <PromptInput
          getData={getData}
          isLoading={isLoading || isAssistantRunning}
          codeInterpreter={codeInterpreter}
          setCodeInterpreter={setCodeInterpreter}
        />
      </div>
    </div >
  );
};

export default ChatV2;
