
import { Input } from "antd";
import { useEffect, useState } from "react";
import rightArrowIcon from "../../../assets/icons/rightArrow.svg"

export const PromptInput = (props) => {
  const { getData, isLoading } = props;
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    setInputVal("");
  }, [isLoading]);
  return (
    <div style={{ display: "flex", }}>
      <Input
        disabled={isLoading}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        suffix={

          <img src={rightArrowIcon} alt="send" style={{
            background: "#5E5ADB",
            padding: "6px",
            borderRadius: "50%",
            cursor: inputVal ? "pointer" : "not-allowed",
          }}
            onClick={() => {
              getData(inputVal);
            }} />
        }
        style={{
          padding: "8px 12px",
          borderRadius: "12px",
          fontSize: "14px",
          fontFamily: "Poppins, sans-serif",
          border: "2px solid #E8ECEF",
          boxShadow: 'none',
          "&::placeholder": {
            color: "red"
          }
        }}

        placeholder="Ask a question..."
        onPressEnter={() => {
          if (inputVal) {
            getData(inputVal);
          }
        }}
      />
    </div>
  );
};
